import { authClient, API } from "../index";

// Get all QRs
export const getQRs = () => {
  return authClient().get(`${API}/QR`);
};

// Get QR by id
export const getQRById = (id) => {
  return authClient().get(`${API}/QR/${id}`);
};

// Create QR
export const postQR = (QR) => {
  return authClient().post(`${API}/QR`, QR);
};

// Delete QR
export const deleteQR = (id) => {
  return authClient().delete(`${API}/QR/${id}`);
};

// Update QR
export const updateQR = (id, QR) => {
  return authClient().put(`${API}/QR/${id}`, QR);
};
