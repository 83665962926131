import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";
import { shallowEqual, useSelector } from "react-redux";
import { DashboardPage } from "./pages/DashboardPage";
import CategoriesPage from "./modules/ContentManager/categories/CategoriesPage";
import EditCategoriesPage from "./modules/ContentManager/categories/EditCategoriesPage";
import TypesPage from "./modules/ContentManager/types/TypesPage";
import EditTypesPage from "./modules/ContentManager/types/EditTypesPage";
import BrandsPage from "./modules/ContentManager/brands/BrandsPage";
import EditBrandsPage from "./modules/ContentManager/brands/EditBrandsPage";
import ProductsPage from "./modules/ContentManager/products/ProductsPage";
import EditProductsPage from "./modules/ContentManager/products/EditProductsPage";
import DownloadsPage from "./modules/ContentManager/download/DownloadsPage";
import EditDownloadsPage from "./modules/ContentManager/download/EditDownloadsPage";
import AdminsPage from "./modules/ContentManager/admins/AdminsPage";
import EditAdminsPage from "./modules/ContentManager/admins/EditAdminsPage";
import TextsPage from "./modules/ContentManager/texts/TextsPage";
import EditTextsPage from "./modules/ContentManager/texts/EditTextsPage";
import LanguagesPage from "./modules/ContentManager/languages/LanguagesPage";
import EditLanguagesPage from "./modules/ContentManager/languages/EditLanguagesPage";
import ContactsPage from "./modules/ContentManager/contacts/ContactsPage";
import ViewContactsPage from "./modules/ContentManager/contacts/ViewContactsPage";
import FormatsPage from "./modules/ContentManager/formats/FormatsPage";
import EditFormatsPage from "./modules/ContentManager/formats/EditFormatsPage";
import QRsPage from "./modules/ContentManager/QRS/QRs";
import EditQRsPage from "./modules/ContentManager/QRS/EditQR";

export default function BasePage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const isAdmin = user || user?.role !== "admin";

  return (
    <Switch>
      {/* Redirect from root URL to /dashboard. */}
      <Redirect exact from="/" to="/dashboard" />
      <ContentRoute path="/dashboard" component={DashboardPage} />

      {/* CATALOGUE */}
      {/* Categories */}
      <ContentRoute from="/categories" component={CategoriesPage} />
      <ContentRoute from="/edit-category/:id?" component={EditCategoriesPage} />
      {/* Types */}
      <ContentRoute from="/types" component={TypesPage} />
      <ContentRoute from="/edit-type/:id?" component={EditTypesPage} />
      {/* Brands */}
      <ContentRoute from="/brands" component={BrandsPage} />
      <ContentRoute from="/edit-brand/:id?" component={EditBrandsPage} />
      {/* Products */}
      <ContentRoute from="/products" component={ProductsPage} />
      <ContentRoute from="/edit-product/:id?" component={EditProductsPage} />
      {/* Formats */}
      <ContentRoute from="/formats" component={FormatsPage} />
      <ContentRoute from="/edit-format/:id?" component={EditFormatsPage} />
      {/* Downloads */}
      <ContentRoute from="/downloads" component={DownloadsPage} />
      <ContentRoute from="/edit-download/:id?" component={EditDownloadsPage} />

      {/* USERS */}

      {/* Contacts */}
      <ContentRoute from="/contacts" component={ContactsPage} />
      <ContentRoute from="/view-contact/:id" component={ViewContactsPage} />
      {/* Administrators */}
      <ContentRoute from="/admins" component={AdminsPage} />
      <ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />

      {/* MISCELLANY */}
      {/* Languages */}
      <ContentRoute
        from="/languages"
        component={!isAdmin ? DashboardPage : LanguagesPage}
      />
      <ContentRoute
        from="/edit-language/:id?"
        component={!isAdmin ? DashboardPage : EditLanguagesPage}
      />
      {/* Texts */}
      <ContentRoute
        from="/texts"
        component={!isAdmin ? DashboardPage : TextsPage}
      />
      <ContentRoute
        from="/edit-text/:id"
        component={!isAdmin ? DashboardPage : EditTextsPage}
      />
      {/* QRs */}
      <ContentRoute from="/QRs" component={QRsPage} />
      <ContentRoute from="/edit-QR/:id?" component={EditQRsPage} />

      {/* ERROR PAGE */}

      <Redirect to="/error" />
    </Switch>
  );
}
