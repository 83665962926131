import { /* baseClient, */ authClient, API } from "../index";
import { uploadSingleFile } from "../files";

// Get all brands
export const getBrands = () => {
  return authClient().get(`${API}/brand`);
};

// Get brand by id
export const getBrandById = (id) => {
  return authClient().get(`${API}/brand/${id}`);
};

// Delete brand
export const deleteBrand = (id) => {
  return authClient().delete(`${API}/brand/${id}`);
};

const isBlockImagesEmpty = (blockImages) => {
  if (
    typeof blockImages === "object" &&
    blockImages !== null &&
    !Array.isArray(blockImages)
  ) {
    return (
      blockImages.image === "" &&
      Object.keys(blockImages.text).length === 0 &&
      Object.keys(blockImages.button).length === 0
    );
  }
  if (Array.isArray(blockImages)) {
    return blockImages.every(
      (block) =>
        block.image === "" &&
        Object.keys(block.text).length === 0 &&
        Object.keys(block.button).length === 0
    );
  }
  return true;
};

// Create brand
export const postBrand = async (brand, image, mainImg, video, blockImages) => {
  if (image) {
    const response = await uploadSingleFile(image, "brands");
    brand.logo = response.data.fileURL;
  }
  if (video) {
    if (video.en && video.es) {
      const responseEn = await uploadSingleFile(video.en, "brands");
      const responseEs = await uploadSingleFile(video.es, "brands");
      brand.video = {
        en: responseEn.data.fileURL,
        es: responseEs.data.fileURL,
      };
    } else if (video.en) {
      const responseEn = await uploadSingleFile(video.en, "brands");
      brand.video = {
        en: responseEn.data.fileURL,
        es: "",
      };
    } else if (video.es) {
      const responseEs = await uploadSingleFile(video.es, "brands");
      brand.video = {
        en: "",
        es: responseEs.data.fileURL,
      };
    }
  }
  if (mainImg) {
    const response2 = await uploadSingleFile(mainImg, "brands");
    brand.mainImg = response2.data.fileURL;
  }
  if (!isBlockImagesEmpty(blockImages)) {
    const nonEmptyBlocks = blockImages?.filter((block) => block.image !== "");
    if (nonEmptyBlocks?.length > 0) {
      const uploadPromises = blockImages.map((block) => {
        if (block.image !== "" && block.image.type)
          return uploadSingleFile(block.image, "brands");
      });
      const responses = await Promise.all(uploadPromises);
      console.log("RESPONSES: ", responses);
      const updatedBlocks = responses.map((response, index) => {
        if (!response) {
          return {
            image: blockImages[index].image ? blockImages[index].image : "",
            text: blockImages[index].text ? blockImages[index].text : "",
            buttons: blockImages[index].buttons
              ? blockImages[index].buttons
              : "",
          };
        }
        return {
          image: response.data.fileURL,
          text: blockImages[index].text,
          buttons: blockImages[index].buttons ? blockImages[index].buttons : "",
        };
      });
      // Update brand.blocks with the updated blocks
      brand.blocks = updatedBlocks;
    }
  }
  return authClient().post(`${API}/brand`, brand);
};

// Update brand
export const updateBrand = async (
  id,
  brand,
  image,
  mainImg,
  video,
  blockImages
) => {
  if (image) {
    const response = await uploadSingleFile(image, "brands");
    brand.logo = response.data.fileURL;
  }
  if (video) {
    if (video.en && video.es) {
      const responseEn = await uploadSingleFile(video.en, "brands");
      const responseEs = await uploadSingleFile(video.es, "brands");
      brand.video = {
        en: responseEn.data.fileURL,
        es: responseEs.data.fileURL,
      };
    } else if (video.en) {
      const responseEn = await uploadSingleFile(video.en, "brands");
      brand.video = {
        en: responseEn.data.fileURL,
        es: "",
      };
    } else if (video.es) {
      const responseEs = await uploadSingleFile(video.es, "brands");
      brand.video = {
        en: "",
        es: responseEs.data.fileURL,
      };
    }
  }
  if (mainImg) {
    const response2 = await uploadSingleFile(mainImg, "brands");
    brand.mainImg = response2.data.fileURL;
  }
  if (!isBlockImagesEmpty(blockImages)) {
    const nonEmptyBlocks = blockImages?.filter((block) => block.image !== "");
    if (nonEmptyBlocks?.length > 0) {
      const uploadPromises = blockImages.map((block) => {
        if (block.image !== "" && block.image.type)
          return uploadSingleFile(block.image, "brands");
      });
      const responses = await Promise.all(uploadPromises);
      const updatedBlocks = responses.map((response, index) => {
        if (!response) {
          return {
            image: blockImages[index].image ? blockImages[index].image : "",
            text: blockImages[index].text ? blockImages[index].text : "",
            button: blockImages[index].button ? blockImages[index].button : "",
          };
        }
        return {
          image: response.data.fileURL,
          text: blockImages[index].text,
          button: blockImages[index].button ? blockImages[index].button : "",
        };
      });
      // Update brand.blocks with the updated blocks
      console.log("UPDATED_BLOCKS: ", updatedBlocks);
      brand.blocks = updatedBlocks;
    }
  }
  return authClient().put(`${API}/brand/${id}`, brand);
};

// Count all types
export const countBrands = () => {
  return authClient().get(`${API}/brand/count`);
};

export const updateBrandsOrder = (id, id2) => {
  return authClient().post(`${API}/brand/swapBrands`, {
    brand1Id: id,
    brand2Id: id2,
  });
};
