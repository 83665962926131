import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import {
  deleteFormat,
  getFormatById,
  postFormat,
  updateFormat,
} from "../../../../api/format";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { checkIsEmpty, getNonEmpty } from "../../../../utils/helpers";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { shallowEqual, useSelector } from "react-redux";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { SERVER_URL } from "../../../../api/index";
import { Visibility } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { buttonsStyle } from "../../../components/tables/table";
import { getProducts } from "../../../../api/product";
import { formatFileName } from "../../../../utils/helpers";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyFormat() {
  return {
    fullName: "",
    quantity: "",
    mainImg: "",
    miniImg: "",
    product: "",
  };
}

export default function EditFormatsPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [format, setFormat] = useState(getEmptyFormat());
  const [products, setProducts] = useState([]);
  const [mainImg, setMainImg] = useState(null);
  const [openPreviewDialogMainImage, setOpenPreviewDialogMainImage] = useState(
    false
  );
  const [miniImg, setMiniImg] = useState(null);
  const [openPreviewDialogMiniImage, setOpenPreviewDialogMiniImage] = useState(
    false
  );
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const formatId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    getProducts()
      .then((res) => {
        if (res.status === 200) {
          setProducts(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido obtener los productos.",
        });
      });
    if (!formatId) {
      disableLoadingData();
      return;
    }
    getFormatById(formatId)
      .then((res) => {
        if (res.status === 200) {
          const format = res.data;
          setFormat(format);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido obtener format.",
        });
        history.push("/formats");
      });
  }, [formatId, disableLoadingData, history]);

  function saveFormat() {
    if (checkIsEmpty(format.fullName))
      return alertError({
        error: null,
        customMessage: "El nombre es requerido.",
      });
    if (checkIsEmpty(format.product))
      return alertError({
        error: null,
        customMessage: "El nombre es requerido.",
      });
    if (!formatId) {
      postFormat(format, mainImg, miniImg)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Format creada correctamente.",
            });
            history.push("/formats");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se ha podido guardar format.",
          });
        });
    } else {
      updateFormat(formatId, format, mainImg, miniImg)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Los cambios se han guardado correctamente.",
            });
            history.push("/formats");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se han podido guardar los cambios.",
          });
        });
    }
  }

  useEffect(() => {
    setSelectedProduct(products.find((p) => p._id === format.product));
  }, [products]);

  const handleChange = (element) => (event) => {
    if (element === "product") {
      setFormat({ ...format, [element]: event?._id });
      return;
    }
    setFormat({ ...format, [element]: event.target.value });
  };

  useEffect(() => {
    console.log("FORMAT", format);
  }, [format]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Editar format"></CardHeader>
          <CardBody>
            <TextField
              id={`fullName`}
              label="Format"
              value={format.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <TextField
              id={`quantity`}
              label="Quantity"
              value={format.quantity}
              onChange={handleChange("quantity")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <span>Product</span>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              className="my-2"
              defaultValue={selectedProduct || ""}
              value={selectedProduct || ""}
              onChange={(event) => {
                console.log(selectedProduct);
                handleChange("product")(event);
                setSelectedProduct(event);
              }}
              options={products}
              getOptionLabel={(option) => `${getNonEmpty(option.fullName)}`}
              getOptionValue={(option) => `${option._id}`}
              placeholder="Seleccione un Product..."
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            <br />
            {/* PART DE LA IMATGE PRINCIPAL */}
            <p>
              Subir imagen del producto desplazada hacia la izquierda para
              mostrar el texto al lado
            </p>
            <label htmlFor={"upload-main-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-main-image"}
                name={"upload-main-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  const originalFile = e.target.files[0];
                  const formattedName = formatFileName(originalFile.name);
                  const blob = new Blob([originalFile], {
                    type: originalFile.type,
                  });
                  const newFile = new File([blob], formattedName, {
                    type: originalFile.type,
                  });
                  setMainImg(newFile);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {mainImg || (format.mainImg && format.mainImg !== "")
                  ? "Change main image"
                  : "Upload main image"}
              </Button>
            </label>
            {(mainImg || (format.mainImg && format.mainImg !== "")) && (
              <>
                <PreviewDialog
                  title={"Preview main Image"}
                  open={openPreviewDialogMainImage}
                  setOpen={setOpenPreviewDialogMainImage}
                  src={
                    mainImg
                      ? URL.createObjectURL(mainImg)
                      : `${SERVER_URL}/${format.mainImg}`
                  }
                />
                <span>
                  {mainImg
                    ? mainImg?.name
                    : format.mainImg && format.mainImg !== ""
                    ? format.mainImg?.split(/-(.*)/s)[1]
                    : ""}
                </span>
                <Tooltip title={"Preview main Image"}>
                  <Button
                    size="small"
                    onClick={() => setOpenPreviewDialogMainImage(true)}
                    style={{ ...buttonsStyle, marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      if (mainImg) setMainImg(null);
                      else setFormat({ ...format, mainImg: "" });
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            <br />
            <br />
            {/* PART DE LA IMATGE SECUNDARIA */}
            <p>
              Subir imagen del producto centrada para mostrarlo como opción de
              formato
            </p>
            <label htmlFor={"upload-mini-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-mini-image"}
                name={"upload-mini-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  const originalFile = e.target.files[0];
                  const formattedName = formatFileName(originalFile.name);
                  const blob = new Blob([originalFile], {
                    type: originalFile.type,
                  });
                  const newFile = new File([blob], formattedName, {
                    type: originalFile.type,
                  });
                  setMiniImg(newFile);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {miniImg || (format.miniImg && format.miniImg !== "")
                  ? "Change format image"
                  : "Upload format image"}
              </Button>
            </label>
            {(miniImg || (format.miniImg && format.miniImg !== "")) && (
              <>
                <PreviewDialog
                  title={"Preview main Image"}
                  open={openPreviewDialogMiniImage}
                  setOpen={setOpenPreviewDialogMiniImage}
                  src={
                    miniImg
                      ? URL.createObjectURL(miniImg)
                      : `${SERVER_URL}/${format.miniImg}`
                  }
                />
                <span>
                  {miniImg
                    ? miniImg?.name
                    : format.miniImg && format.miniImg !== ""
                    ? format.miniImg?.split(/-(.*)/s)[1]
                    : ""}
                </span>
                <Tooltip title={"Preview mini Image"}>
                  <Button
                    size="small"
                    onClick={() => setOpenPreviewDialogMiniImage(true)}
                    style={{ ...buttonsStyle, marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      if (miniImg) setMiniImg(null);
                      else setFormat({ ...format, miniImg: "" });
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/formats")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Atrás
        </Button>
        <Button
          onClick={() => saveFormat()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Guardar format
        </Button>
        {formatId && user?.role === "admin" && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Eliminar format
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"¿Estás seguro de que quieres eliminar esta format?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteFormat(formatId)
                  .then((res) => {
                    if (res.status === 204) {
                      alertSuccess({
                        title: "¡Eliminado!",
                        customMessage: "Format eliminada correctamente",
                      });
                      history.push("/formats");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "No se ha podido eliminar la format.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
