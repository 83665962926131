import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import Table, { buttonsStyle, dateFormatter, longTextFormatter } from '../../../components/tables/table'
import { getContacts } from '../../../../api/contact'
import { Button, Tooltip } from '@material-ui/core'
import { alertError } from '../../../../utils/logger'
import { useHistory } from 'react-router-dom'
import VisibilityIcon from '@material-ui/icons/Visibility'

function getData(contacts) {
  let data = []
  for (let i = 0; i < contacts.length; ++i) {
      const elem = {}
      elem.name = contacts[i].name
      elem.email = contacts[i].email
      elem.subject = contacts[i].subject
      elem.message = contacts[i].message
      elem.date = contacts[i].createdAt
      elem.id = contacts[i]._id
      data = data.concat(elem)
  }
  return data
}

export default function ContactsPage() {
  const [data, setData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title="View">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/view-contact/' + cell)}>
          <VisibilityIcon/>
        </Button>
      </Tooltip>
    </>)
  }

  const columns = [
    { dataField: 'name', text: 'Full name', sort: true },
    { dataField: 'email', text: 'Email', sort: true },
    { dataField: 'subject', text: 'Asunto', sort: true },
    { dataField: 'message', text: 'Message', formatter: longTextFormatter, sort: true },
    { dataField: 'date', text: 'Date', formatter: dateFormatter, sort: true },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getContacts().then((res) => {
      if (res.status === 200) {
        setData(getData(res.data))
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get contacts.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Contacts list'/>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
        </CardBody>
      </Card>
    </>
  );
}
