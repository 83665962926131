import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  Tooltip,
} from "@material-ui/core";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteType,
  getTypeById,
  postType,
  updateType,
} from "../../../../api/type";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import { getCategories } from "../../../../api/category";
import { shallowEqual, useSelector } from "react-redux";
import { getNonEmpty } from "../../../../utils/helpers";
import { formatFileName } from "../../../../utils/helpers";
import { buttonsStyle } from "../../../components/tables/table";
import { Visibility } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { SERVER_URL } from "../../../../api";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyType() {
  return {
    fullName: {},
    category: "",
    mainImg: "",
  };
}

export default function EditTypesPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [type, setType] = useState(getEmptyType());
  const [categories, setCategories] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [mainImg, setMainImg] = useState(null);
  const [openPreviewDialogMainImage, setOpenPreviewDialogMainImage] = useState(
    false
  );
  const typeId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    getCategories()
      .then((res) => {
        if (res.status === 200) {
          setCategories(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener las categories.",
        });
        history.push("/types");
      });
    if (!typeId) {
      disableLoadingData();
      return;
    }
    getTypeById(typeId)
      .then((res) => {
        if (res.status === 200) {
          setType(res.data);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido obtener la type.",
        });
        history.push("/types");
      });
  }, [typeId, disableLoadingData, history]);

  function saveType() {
    if (!typeId) {
      postType(type, mainImg)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Type creada correctamente.",
            });
            history.push("/types");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se ha podido guardar la type.",
          });
        });
    } else {
      updateType(typeId, type, mainImg)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Los cambios se han guardado correctamente.",
            });
            history.push("/types");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se han podido guardar los cambios.",
          });
        });
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (element === "category") {
      setType({ ...type, [element]: event._id });
    } else {
      if (lang) {
        if (!type[element]) type[element] = {};
        let newText = type[element];
        newText[lang] = event.target.value;
        setType({ ...type, [element]: newText });
      } else setType({ ...type, [element]: event.target.value });
    }
  };

  const renderMultilanguageTabContent = (lang) => {
    return (
      <>
        <br />
        <strong style={{ color: "#ff0033" }}>
          Recuerda que si quieres que este tipo de producto vaya a mundo
          coctelería, debes añadir un espacio en blanco al final del producto
          para que su url sea única
        </strong>
        <br />
        <strong style={{ color: "#ff0033" }}>Ejemplo: "Cachaça "</strong>
        <br />
        <TextField
          id={`fullName`}
          label="Nombre"
          value={(type.fullName && type.fullName[lang]) || ""}
          onChange={handleChange("fullName", lang)}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
          required
        />
      </>
    );
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Editar type"></CardHeader>
          <CardBody>
            <MultilanguageTabBlock
              multilanguageTabContent={renderMultilanguageTabContent}
            />
            <br />
            <span>Category</span>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              className="my-2"
              defaultValue={
                type.category && categories
                  ? categories.find((item) => item._id === type.category)
                  : ""
              }
              onChange={handleChange("category")}
              options={categories}
              getOptionLabel={(option) => `${getNonEmpty(option.name)}`}
              getOptionValue={(option) => `${option._id}`}
              placeholder="Seleccione una category..."
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            <br />
            {/* PART DE LA IMATGE PRINCIPAL */}
            <p>Subir imagen del tipo de producto</p>
            <label htmlFor={"upload-main-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-main-image"}
                name={"upload-main-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  const originalFile = e.target.files[0];
                  const formattedName = formatFileName(originalFile.name);
                  const blob = new Blob([originalFile], {
                    type: originalFile.type,
                  });
                  const newFile = new File([blob], formattedName, {
                    type: originalFile.type,
                  });
                  setMainImg(newFile);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {mainImg || (type?.mainImg && type?.mainImg !== "")
                  ? "Change main image"
                  : "Upload main image"}
              </Button>
            </label>
            {(mainImg || (type?.mainImg && type?.mainImg !== "")) && (
              <>
                <PreviewDialog
                  title={"Preview main Image"}
                  open={openPreviewDialogMainImage}
                  setOpen={setOpenPreviewDialogMainImage}
                  src={
                    mainImg
                      ? URL.createObjectURL(mainImg)
                      : `${SERVER_URL}/${type?.mainImg}`
                  }
                />
                <span>
                  {mainImg
                    ? mainImg?.name
                    : type?.mainImg && type?.mainImg !== ""
                    ? type?.mainImg?.split(/-(.*)/s)[1]
                    : ""}
                </span>
                <Tooltip title={"Preview main Image"}>
                  <Button
                    size="small"
                    onClick={() => setOpenPreviewDialogMainImage(true)}
                    style={{ ...buttonsStyle, marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      if (mainImg) setMainImg(null);
                      else setType({ ...type, mainImg: "" });
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            <br />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/types")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Atrás
        </Button>
        <Button
          onClick={() => saveType()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Guardar type
        </Button>
        {typeId && user?.role === "admin" && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Eliminar type
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"¿Estás seguro de que quieres eliminar esta type?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteType(typeId)
                  .then((res) => {
                    if (res.status === 204) {
                      alertSuccess({
                        title: "¡Eliminada!",
                        customMessage: "Type eliminada correctamente.",
                      });
                      history.push("/types");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "No se ha podido eliminar la type.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
