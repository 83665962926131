import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { alertError, alertSuccess } from "../../../../utils/logger";
import Table, {
  buttonsStyle,
  dateFormatter,
  longTextFormatter,
} from "../../../components/tables/table";
import { deleteQR, getQRs } from "../../../../api/QR";
import { Modal } from "react-bootstrap";
import {
  Button,
  Tooltip,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

function getData(QRs) {
  let data = [];
  for (let i = 0; i < QRs.length; ++i) {
    const elem = {};
    elem.id = QRs[i].QRId;
    elem.redirectURL = QRs[i].redirectURL;
    elem.counter = QRs[i].counter;
    elem.userAccessed = QRs[i].userAccessed;
    data = data.concat(elem);
  }
  return data;
}

export default function QRsPage() {
  const [data, setData] = useState([]);
  const [QRId, setQRId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  function buttonFormatter(cell) {
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-QR/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
      </>
    );
  }

  function userAccessedFormatter(cell, row) {
    if (Array.isArray(cell) && cell.length > 0) {
      const firstThree = cell.slice(0, 3);
      const remaining = cell.length - firstThree.length;
      return (
        <>
          {firstThree.map((access, index) => (
            <div key={index}>{`${access.userIp} on ${access.date}`}</div>
          ))}
          {remaining > 0 && (
            <div
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                setModalData(cell);
                setShowModal(true);
              }}
            >
              {`y ${remaining} más...`}
            </div>
          )}
        </>
      );
    }
    return "No Access Recorded";
  }

  useEffect(() => {
    console.log(modalData);
  }, [modalData]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      formatter: (cell, row) => (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => {
            navigator.clipboard.writeText(
              `https://destileriasjborrajo.net/qr?${row.id}`
            );
            alertSuccess({ customMessage: "Content copied to clipboard." });
          }}
        >
          https://destileriasjborrajo.net/qr?{row.id}
        </span>
      ),
    },
    {
      dataField: "redirectURL",
      text: "Redirect URL",
      sort: true,
      formatter: (cell, row) => (
        <span
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() => {
            navigator.clipboard.writeText(row.redirectURL);
            alertSuccess({ customMessage: "Content copied to clipboard." });
          }}
        >
          {row.redirectURL}
        </span>
      ),
    },
    {
      dataField: "counter",
      text: "Counter",
      sort: true,
    },
    {
      dataField: "userAccessed",
      text: "User Accessed",
      formatter: userAccessedFormatter,
      sort: true,
    },
    {
      dataField: "id",
      text: "Actions",
      formatter: buttonFormatter,
      headerStyle: { width: "100px" },
    },
  ];

  useEffect(() => {
    getQRs()
      .then((res) => {
        if (res.status === 200) {
          setData(getData(res.data));
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get QRs." });
      });
  }, [refresh]);

  const UserAccessModal = ({ show, handleClose, userAccessed }) => {
    const userAccessColumns = [
      { dataField: "userIp", text: "User IP", sort: true },
      { dataField: "date", text: "Date", sort: true },
    ];

    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Datos de los usuarios que han accedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table data={userAccessed} columns={userAccessColumns} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <Card>
        <CardHeader title="Lista de QRs">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-QR")}
            >
              Añadir nuevo
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={data} columns={columns} />
          <UserAccessModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            userAccessed={modalData}
          />

          <ConfirmDialog
            title={"¿Estás seguro de que quieres eliminar esta QR?"}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteQR(QRId)
                .then((res) => {
                  if (res.status === 204) {
                    alertSuccess({
                      title: "¡Eliminada!",
                      customMessage: "QR eliminada correctamente.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "No se ha podido eliminar la QR.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
