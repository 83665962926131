import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  getDownloads,
  deleteDownload,
  updateDownloadsOrder,
} from "../../../../api/download";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { getNonEmpty } from "../../../../utils/helpers";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

function getData(downloads) {
  let data = [];
  for (let i = 0; i < downloads.length; ++i) {
    const elem = {};
    elem.name = getNonEmpty(downloads[i].fullName);
    elem.createdAt = downloads[i].createdAt;
    elem.id = downloads[i]._id;
    data = data.concat(elem);
  }
  return data;
}

export default function DownloadsPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [downloadId, setDownloadId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  const handleSort = async (direction, cell) => {
    const currentIndex = filteredData.findIndex((item) => item._id === cell);
    let swapIndex = -1;

    if (direction === "up" && currentIndex > 0) {
      swapIndex = currentIndex - 1;
    } else if (direction === "down" && currentIndex < filteredData.length - 1) {
      swapIndex = currentIndex + 1;
    }

    if (swapIndex !== -1) {
      let newData = [...filteredData];
      [newData[currentIndex], newData[swapIndex]] = [
        newData[swapIndex],
        newData[currentIndex],
      ];
      updateDownloadsOrder(
        newData[currentIndex]._id,
        newData[swapIndex]._id
      ).then((res) => {
        if (res.status === 200) setRefresh(true);
        else
          alertError({
            error: res,
            customMessage: "No se ha podido actualizar el orden.",
          });
      });
    }
  };

  function buttonFormatter(cell) {
    let deleteButton;
    let updownButton;
    if (user?.role === "admin")
      deleteButton = (
        <Tooltip title="Eliminar">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setDownloadId(cell);
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      );
    updownButton = (
      <>
        <Tooltip title="Move up">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => handleSort("up", cell)}
          >
            <ArrowUpwardIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Move down">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => handleSort("down", cell)}
          >
            <ArrowDownwardIcon />
          </Button>
        </Tooltip>
      </>
    );
    return (
      <>
        <Tooltip title="Editar">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-download/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {deleteButton}
        {updownButton}
      </>
    );
  }

  const columns = [
    { dataField: "name", text: "Download", sort: true },
    {
      dataField: "createdAt",
      text: "Fecha de creación",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "id",
      text: "",
      formatter: buttonFormatter,
      searchable: false,
    },
  ];

  useEffect(() => {
    getDownloads()
      .then((res) => {
        if (res.status === 200) {
          const sortedData = res.data.sort((a, b) => a.order - b.order);
          setFilteredData(sortedData);
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener las downloads.",
        });
      });
  }, [refresh]);

  return (
    <>
      <Card>
        <CardHeader title="Lista de Downloads">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-download")}
            >
              Añadir nueva
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={getData(filteredData)} columns={columns} />

          <ConfirmDialog
            title={"¿Estás seguro de que quieres eliminar esta download?"}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteDownload(downloadId)
                .then((res) => {
                  if (res.status === 204) {
                    alertSuccess({
                      title: "¡Eliminada!",
                      customMessage: "Download eliminada correctamente.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "No se ha podido eliminar la download.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
