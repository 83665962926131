import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  Tooltip,
  InputLabel,
  MenuItem,
  FormGroup,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteProduct,
  getProductById,
  getProducts,
  postProduct,
  updateProduct,
} from "../../../../api/product";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import { checkIsEmpty, getNonEmpty } from "../../../../utils/helpers";
import { getBrands } from "../../../../api/brand";
import { getTypes } from "../../../../api/type";
import { getCategories } from "../../../../api/category";
import Editor from "../../../components/editor/Editor";
import { SERVER_URL } from "../../../../api/index";
import { Visibility } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { formatFileName } from "../../../../utils/helpers";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyProduct() {
  return {
    fullName: {},
    subtitle: {},
    description: "",
    type: "",
    type2: "",
    brand: "",
    active: true,
    mainImage: "",
    blocks: [
      { image: "", text: {} },
      { image: "", text: {} },
      { image: "", text: {} },
    ],
    socialMedia: {
      instagram: "",
      facebook: "",
      youtube: "",
      web: "",
    },
    footerImage: "",
    pdfName: "",
    hideName: false,
    color: "",
    pdfFile: "",
    relatedProducts: [],
  };
}

export default function EditProductsPage() {
  const [product, setProduct] = useState(getEmptyProduct());
  const [allProducts, setAllProducts] = useState([]);
  const [brands, setBrands] = useState(null);
  const [categories, setCategories] = useState(null);
  const [types, setTypes] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedType2, setSelectedType2] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategory2, setSelectedCategory2] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [mainImg, setMainImg] = useState(null);
  const [pdfFL, setPdfFL] = useState(null);
  const [blockImages, setBlockImages] = useState(null);
  const [openPreviewPdfFile, setopenPreviewPdfFile] = useState(false);
  const [openPreviewDialogMain, setOpenPreviewDialogMain] = useState(false);
  const [openPreviewDialogBlock1, setOpenPreviewDialogBlock1] = useState(false);
  const [openPreviewDialogBlock2, setOpenPreviewDialogBlock2] = useState(false);
  const [openPreviewDialogBlock3, setOpenPreviewDialogBlock3] = useState(false);
  const [footerImage, setFooterImage] = useState(null);
  const [openPreviewDialogFooter, setOpenPreviewDialogFooter] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState({
    instagram: product?.socialMedia?.instagram !== "",
    facebook: product?.socialMedia?.facebook !== "",
    youtube: product?.socialMedia?.youtube !== "",
    web: product?.socialMedia?.web !== "",
  });

  const productId = useParams().id;
  const history = useHistory();

  const handlePlatformChange = (platform) => (event) => {
    setSelectedPlatforms({
      ...selectedPlatforms,
      [platform]: event.target.checked,
    });
    // If the platform is unchecked, clear the URL
    if (!event.target.checked) {
      setProduct({
        ...product,
        socialMedia: {
          ...product.socialMedia,
          [platform]: "",
        },
      });
    }
  };

  const handleLinkChange = (platform) => (event) => {
    setProduct({
      ...product,
      socialMedia: {
        ...product.socialMedia,
        [platform]: event.target.value,
      },
    });
  };

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    getBrands()
      .then((res) => {
        if (res.status === 200) {
          setBrands(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener las brands.",
        });
        history.push("/products");
      });
    getProducts()
      .then((res) => {
        if (res.status === 200) {
          setAllProducts(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener los products.",
        });
        history.push("/products");
      });
    getCategories()
      .then((res) => {
        if (res.status === 200) {
          setCategories(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener las categories.",
        });
        history.push("/products");
      });
    getTypes()
      .then((res) => {
        if (res.status === 200) {
          setTypes(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener las types.",
        });
        history.push("/products");
      });
    if (!productId) {
      disableLoadingData();
      return;
    }
    getProductById(productId)
      .then((res) => {
        if (res.status === 200) {
          const fetchedProduct = res.data;
          if (!fetchedProduct.blocks) {
            fetchedProduct.blocks = [
              { image: "", text: "" },
              { image: "", text: "" },
              { image: "", text: "" },
            ];
          } else if (fetchedProduct.blocks.length < 3) {
            const missingBlocks = 3 - fetchedProduct.blocks.length;
            for (let i = 0; i < missingBlocks; i++) {
              fetchedProduct.blocks.push({ image: "", text: "" });
            }
          }
          setProduct(fetchedProduct);
          // console.log("FINAL PRODUCT: ", fetchedProduct);
          disableLoadingData();
          // console.log("ADDITIONAL DATA", res.data.additionalData?.type);
          // setSelectedType(res.data.additionalData?.type);
          // setSelectedCategory(res.data.additionalData?.category);
          // setSelectedType2(res.data.additionalData?.type2);
          // setSelectedCategory2(res.data.additionalData?.category2);
          setSelectedPlatforms({
            instagram: !!fetchedProduct.socialMedia?.instagram,
            facebook: !!fetchedProduct.socialMedia?.facebook,
            youtube: !!fetchedProduct.socialMedia?.youtube,
            web: !!fetchedProduct.socialMedia?.web,
          });
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido obtener el product.",
        });
        history.push("/products");
      });
  }, [productId, disableLoadingData, history]);

  function saveProduct() {
    // console.log(product);
    if (checkIsEmpty(product.fullName))
      return alertError({
        error: null,
        customMessage: "El nombre es requerido.",
      });
    if (!product.type) {
      return alertError({
        error: null,
        customMessage: "El type es requerido.",
      });
    }
    if (!productId) {
      postProduct(product, mainImg, blockImages, footerImage, pdfFL)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Product creado correctamente.",
            });
            history.push("/products");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save product.",
          });
        });
    } else {
      updateProduct(
        productId,
        product,
        mainImg,
        blockImages,
        footerImage,
        pdfFL
      )
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Los cambios se han guardado correctamente.",
            });
            history.push("/products");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se han podido guardar los cambios.",
          });
        });
    }
  }

  const handleChange = (element, lang) => (event) => {
    // console.log("ELEMENT Y EVENTO: ", element, event);
    if (
      element === "brand" ||
      element === "category" ||
      element === "type" ||
      element === "type2"
    ) {
      setProduct({ ...product, [element]: event.target.value });
    } else if (element === "relatedProducts") {
      const options = event.target.value;
      const value = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i] != "No relatedProducts selected") value.push(options[i]);
      }
      setProduct({ ...product, relatedProducts: value });
    } else if (
      element.startsWith("blockImage") ||
      element.startsWith("blockText")
    ) {
      const blockIndex = parseInt(element.split("_")[1]);
      const newBlocks = [...product.blocks];
      if (element.startsWith("blockImage")) {
        newBlocks[blockIndex].image = event.target.files[0];
      } else {
        newBlocks[blockIndex].text = event.target.value;
      }
      setProduct({ ...product, blocks: newBlocks });
    }
    // else if (element == "relatedProducts") {
    //   const relatedProductsIds = Object.values(event).map((item) => item._id);
    //   setProduct({ ...product, relatedProducts: relatedProductsIds });
    // }
    else {
      if (lang) {
        const newText =
          typeof product[element] === "object" ? product[element] : {};
        setProduct({
          ...product,
          [element]: {
            ...newText,
            [lang]: event.target.value,
          },
        });
      } else setProduct({ ...product, [element]: event.target.value });
    }
  };

  const handleChangeEditor = (element, value, lang) => {
    if (value === " ") return;
    if (lang) {
      if (!product[element]) product[element] = {};
      let newText = product[element];
      newText[lang] = value;
      setProduct({ ...product, [element]: newText });
    } else setProduct({ ...product, [element]: value });
  };

  const handleDeleteFile = () => {
    // TODO: DELETE FILES FROM SERVER
    let newFiles = [...product.footerImage];
    setProduct({ ...product, footerImage: null });
  };

  const handleHideName = () => {
    setProduct({ ...product, hideName: !product.hideName });
  };

  useEffect(() => {
    // console.log("SELECTED TYPE: ", selectedType);
    // console.log("Selected type 2: ", selectedType2);
    // console.log("Selected category: ", selectedCategory);
    // console.log("Selected category 2: ", selectedCategory2);
  }, [selectedCategory2]);

  const renderMultilanguageTabContent = (lang) => {
    return (
      <>
        <br />
        <TextField
          id={`fullName`}
          label="Nombre"
          value={(product.fullName && product.fullName[lang]) || ""}
          onChange={handleChange("fullName", lang)}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
          required
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={product?.hideName || false}
              onChange={handleHideName}
              name="checkedB"
              color="primary"
            />
          }
          label="Ocultar nombre en el producto"
        />
        <br />
        <TextField
          id={`subtitle`}
          label="Subtitulo"
          value={product.subtitle?.[lang] ?? ""}
          onChange={handleChange("subtitle", lang)}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
        />
        <br />
        <Editor
          body={(product.description && product.description[lang]) || ""}
          setBody={(new_body) =>
            handleChangeEditor("description", new_body, lang)
          }
          className="max-height"
          placeholder={"Enter product description here..."}
          label="Description *"
        />
        <br />
        {[0, 1, 2].map((index) => (
          <div key={index}>
            <label htmlFor={`upload-block-image-${index}`}>
              <input
                style={{ display: "none" }}
                id={`upload-block-image-${index}`}
                name={`upload-block-image-${index}`}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  let newBlockImages = [
                    { image: "", text: {} },
                    { image: "", text: {} },
                    { image: "", text: {} },
                  ];

                  if (blockImages) newBlockImages = [...blockImages];
                  else newBlockImages = [...product.blocks];

                  const originalFile = e.target.files[0];
                  const formattedName = formatFileName(originalFile.name);

                  const blob = new Blob([originalFile], {
                    type: originalFile.type,
                  });

                  const newFile = new File([blob], formattedName, {
                    type: originalFile.type,
                  });

                  newBlockImages[index].image = newFile;
                  setBlockImages(newBlockImages);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {(blockImages && blockImages[index]) ||
                (product.blocks[index]?.image &&
                  product.blocks[index]?.image !== "")
                  ? `Change block ${index + 1} image`
                  : `Upload block ${index + 1} image`}
              </Button>
            </label>
            {((blockImages && blockImages[index]) ||
              (product.blocks[index]?.image &&
                product.blocks[index]?.image !== "")) && (
              <>
                <Tooltip title={`Preview block ${index + 1} Image`}>
                  <Button
                    size="small"
                    onClick={() => {
                      if (index === 0) setOpenPreviewDialogBlock1(true);
                      if (index === 1) setOpenPreviewDialogBlock2(true);
                      if (index === 2) setOpenPreviewDialogBlock3(true);
                    }}
                    style={{ marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      let newBlockImages = [
                        { image: "", text: "" },
                        { image: "", text: "" },
                        { image: "", text: "" },
                      ];
                      if (blockImages) newBlockImages = [...blockImages];
                      else newBlockImages = [...product.blocks];
                      newBlockImages[index].image = "";
                      product.blocks[index].image = "";
                      setBlockImages(newBlockImages);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
                <PreviewDialog
                  title={"Preview logo"}
                  open={
                    index === 0
                      ? openPreviewDialogBlock1
                      : index === 1
                      ? openPreviewDialogBlock2
                      : openPreviewDialogBlock3
                  }
                  setOpen={
                    index === 0
                      ? setOpenPreviewDialogBlock1
                      : index === 1
                      ? setOpenPreviewDialogBlock2
                      : setOpenPreviewDialogBlock3
                  }
                  src={
                    blockImages &&
                    (blockImages[index]?.image instanceof Blob ||
                      blockImages[index]?.image instanceof File)
                      ? URL.createObjectURL(blockImages[index]?.image)
                      : blockImages && blockImages[index]?.image
                      ? `${SERVER_URL}/${blockImages[index]?.image}`
                      : `${SERVER_URL}/${product?.blocks[index]?.image}`
                  }
                />
                <span>
                  {blockImages && blockImages[index]?.image?.name
                    ? blockImages[index]?.image?.name
                    : product.blocks[index]?.image &&
                      product.blocks[index]?.image !== ""
                    ? product.blocks[index]?.image.split(/-(.*)/s)[1]
                    : ""}
                </span>
              </>
            )}
            <br />
            <Editor
              label={`Block ${index + 1} Text`}
              body={
                blockImages
                  ? blockImages[index]?.text?.[lang] || ""
                  : product?.blocks[index]?.text?.[lang] || ""
              }
              setBody={(e) => {
                let updatedBlockTexts = blockImages
                  ? [...blockImages]
                  : [...product.blocks];
                let updatedText = updatedBlockTexts[index]?.text || {};

                // console.log(updatedText);
                // console.log(updatedBlockTexts);

                if (
                  typeof updatedText !== "object" ||
                  !updatedText.hasOwnProperty("es") ||
                  !updatedText.hasOwnProperty("en")
                ) {
                  updatedText = { es: "", en: "" };
                }

                updatedText[lang] = e;

                updatedBlockTexts[index] = {
                  ...updatedBlockTexts[index],
                  text: updatedText,
                };

                setBlockImages(updatedBlockTexts);
              }}
              className="max-height"
              placeholder={"Enter product description here..."}
            />
            <br />
            <br />
          </div>
        ))}
      </>
    );
  };

  // useEffect(() => {
  //   console.log("BLOCK_IMAGES: ", blockImages);
  // }, [blockImages]);

  // useEffect(() => {
  //   console.log("FOOTER_IMAGE: ", footerImage);
  // }, [footerImage]);

  useEffect(() => {
    // console.log("PRODUCT UPDATE: ", product);
  }, [product]);

  // useEffect(() => {
  //   console.log("SELECTED CATEGORY: ", selectedCategory);
  //   console.log("CATEGORY: ", categories);
  //   console.log(
  //     "CATEGORIA COINCIDENT: ",
  //     getNonEmpty(
  //       categories?.find((c) => c._id === selectedCategory?._id)?.name
  //     )
  //   );
  // }, [categories, selectedCategory, product]);

  // useEffect(() => {
  //   console.log(product?.additionalData?.category2);
  //   console.log(selectedCategory);
  // }, [product?.additionalData?.category2, selectedCategory2]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Editar product"></CardHeader>
          <CardBody>
            <MultilanguageTabBlock
              multilanguageTabContent={renderMultilanguageTabContent}
            />
            {/* NEW VERSION */}
            <label htmlFor={"upload-main-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-main-image"}
                name={"upload-main-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  const originalFile = e.target.files[0];
                  const formattedName = formatFileName(originalFile.name);
                  const blob = new Blob([originalFile], {
                    type: originalFile.type,
                  });
                  const newFile = new File([blob], formattedName, {
                    type: originalFile.type,
                  });
                  setMainImg(newFile);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {mainImg || (product.mainImage && product.mainImage !== "")
                  ? "Change main image"
                  : "Upload main image"}
              </Button>
            </label>
            {(mainImg || (product.mainImage && product.mainImage !== "")) && (
              <>
                <PreviewDialog
                  title={"Preview Main Image"}
                  open={openPreviewDialogMain}
                  setOpen={setOpenPreviewDialogMain}
                  src={
                    mainImg
                      ? URL.createObjectURL(mainImg)
                      : `${SERVER_URL}/${product.mainImage}`
                  }
                />
                <span>
                  {mainImg
                    ? mainImg?.name
                    : product.mainImage && product.mainImage !== ""
                    ? product.mainImage?.split(/-(.*)/s)[1]
                    : ""}
                </span>
                <Tooltip title={"Preview main Image"}>
                  <Button
                    size="small"
                    onClick={() => {
                      setOpenPreviewDialogMain(true);
                    }}
                    style={{ marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      if (mainImg) setMainImg(null);
                      else setProduct({ ...product, mainImage: "" });
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            <br />
            <br />
            {/* FOOTER IMAGE */}
            <label htmlFor={"upload-footer-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-footer-image"}
                name={"upload-footer-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  const originalFile = e.target.files[0];
                  const formattedName = formatFileName(originalFile.name);
                  const blob = new Blob([originalFile], {
                    type: originalFile.type,
                  });
                  const newFile = new File([blob], formattedName, {
                    type: originalFile.type,
                  });
                  setFooterImage(newFile);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {footerImage ||
                (product.footerImage && product.footerImage !== "")
                  ? "Change footer image"
                  : "Upload footer image"}
              </Button>
            </label>
            {(footerImage ||
              (product.footerImage && product.footerImage !== "")) && (
              <>
                <span>
                  {footerImage
                    ? footerImage?.name
                    : product.footerImage && product.footerImage !== ""
                    ? product.footerImage?.split(/-(.*)/s)[1]
                    : ""}
                </span>
                <Tooltip title={"Preview footer Image"}>
                  <Button
                    size="small"
                    onClick={() => {
                      setOpenPreviewDialogFooter(true);
                    }}
                    style={{ marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <PreviewDialog
                  title={"Preview Footer Image"}
                  open={openPreviewDialogFooter}
                  setOpen={setOpenPreviewDialogFooter}
                  src={
                    footerImage
                      ? URL.createObjectURL(footerImage)
                      : `${SERVER_URL}/${product.footerImage}`
                  }
                />
                <Tooltip title="Delete">
                  <Button size="small" onClick={() => handleDeleteFile()}>
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            <br />
            <br />
            {/* Personalized PDF Name */}
            <label htmlFor={"upload-pdf"}>
              <input
                style={{ display: "none" }}
                id={"upload-pdf"}
                name={"upload-pdf"}
                type="file"
                accept={"pdf/*"}
                onChange={(e) => {
                  setPdfFL(e.target.files[0]);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {pdfFL || (product.pdfFile && product.pdfFile !== "")
                  ? "Change PDF File"
                  : "Upload PDF File"}
              </Button>
            </label>
            {(pdfFL || (product.pdfFile && product.pdfFile !== "")) && (
              <>
                <PreviewDialog
                  title={"Preview PDF File"}
                  open={openPreviewPdfFile}
                  setOpen={setopenPreviewPdfFile}
                  src={
                    pdfFL
                      ? URL.createObjectURL(pdfFL)
                      : `${SERVER_URL}/${product.pdfFile}`
                  }
                />
                <span>
                  {pdfFL
                    ? pdfFL?.name
                    : product.pdfFile && product.pdfFile !== ""
                    ? product.pdfFile?.split(/-(.*)/s)[1]
                    : ""}
                </span>
                <Tooltip title={"Preview PDF File"}>
                  <Button
                    size="small"
                    onClick={() => {
                      setopenPreviewPdfFile(true);
                    }}
                    style={{ marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      if (pdfFL) setPdfFL(null);
                      else setProduct({ ...product, pdfFile: "" });
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            <TextField
              label="PDF Name"
              value={product.pdfName || ""}
              onChange={handleChange("pdfName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="Color Title & Text (Hexadecimal example: #000000); Default color is white"
              value={product.color || ""}
              onChange={handleChange("color")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
            />
            <br />
            <FormControl className="my-2 w-100">
              <InputLabel id="category">Category</InputLabel>
              <Select
                labelId="category"
                id="category"
                className="my-2"
                value={
                  selectedCategory
                    ? selectedCategory
                    : product.additionalData?.category
                    ? product.additionalData.category?._id
                    : ""
                }
                onChange={(event) => {
                  const newValue = event.target.value;

                  let newProduct = {
                    ...product,
                    type: null,
                    type2:
                      newValue === selectedCategory2 ||
                      newValue === product.additionalData?.category2?._id
                        ? null
                        : product.type2,
                    additionalData: {
                      ...product.additionalData,
                      type: null,
                      type2:
                        newValue === selectedCategory2 ||
                        newValue === product.additionalData?.category2?._id
                          ? null
                          : product.additionalData.type2,
                      category2:
                        newValue === selectedCategory2 ||
                        newValue === product.additionalData?.category2?._id
                          ? null
                          : product.additionalData.category2,
                    },
                  };
                  setProduct(newProduct);
                  setSelectedCategory(newValue);
                  if (
                    newValue === selectedCategory2 ||
                    newValue === product.additionalData?.category2?._id
                  ) {
                    setSelectedCategory2(null);
                  }
                }}
                placeholder="Seleccione una category..."
                MenuProps={{
                  PaperProps: {
                    style: {
                      zIndex: 9999,
                    },
                  },
                }}
              >
                {categories?.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {getNonEmpty(category.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {(selectedCategory || product.additionalData?.category) && (
              <>
                <FormControl className="my-2 w-100">
                  <InputLabel id="type">Type</InputLabel>
                  <Select
                    labelId="type"
                    id="type"
                    className="my-2"
                    selectedCategory
                    value={
                      selectedType
                        ? selectedType
                        : product?.additionalData?.type
                        ? product.additionalData?.type._id
                        : ""
                    }
                    onChange={(event) => {
                      setProduct({ ...product, type: event.target.value });
                      setSelectedType(event.target.value);
                    }}
                    placeholder="Seleccione un type..."
                    MenuProps={{
                      PaperProps: {
                        style: {
                          zIndex: 9999,
                        },
                      },
                    }}
                  >
                    {types
                      .filter((item) =>
                        item.category === selectedCategory
                          ? selectedCategory
                          : product.additionalData?.category?._id
                      )
                      .map((type) => (
                        <MenuItem key={type._id} value={type._id}>
                          {getNonEmpty(type.fullName)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </>
            )}
            <FormControl className="my-2 w-100">
              <InputLabel id="category-2">Category 2</InputLabel>
              <Select
                labelId="category-2"
                id="category-2"
                className="my-2"
                value={
                  selectedCategory2
                    ? selectedCategory2
                    : product.additionalData?.category2
                    ? product.additionalData.category2._id
                    : ""
                }
                onChange={(event) => {
                  const newValue = event.target.value;

                  let newProduct = {
                    ...product,
                    type2: newValue === "No" ? null : product.type2,
                    type:
                      newValue === selectedCategory ||
                      newValue === product.additionalData?.category?._id
                        ? null
                        : product.type,
                    additionalData: {
                      ...product.additionalData,
                      type2:
                        newValue === "No" ? null : product.additionalData.type2,
                      type:
                        newValue === selectedCategory ||
                        newValue === product.additionalData?.category?._id
                          ? null
                          : product.additionalData.type,
                      category2:
                        newValue !== "No"
                          ? newValue
                          : product.additionalData.category2,
                      category:
                        newValue === selectedCategory ||
                        newValue === product.additionalData?.category?._id
                          ? null
                          : product.additionalData.category,
                    },
                  };

                  setProduct(newProduct);

                  if (newValue === "No") {
                    setSelectedCategory2(null);
                  } else {
                    setSelectedCategory2(newValue);
                  }

                  if (
                    newValue === selectedCategory ||
                    newValue === product.additionalData?.category?._id
                  ) {
                    setSelectedCategory(null);
                  }
                }}
                placeholder="Seleccione una category..."
                MenuProps={{
                  PaperProps: {
                    style: {
                      zIndex: 9999,
                    },
                  },
                }}
              >
                <MenuItem value="No">No</MenuItem>
                {categories?.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {getNonEmpty(category.name)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {(selectedCategory2 || product.additionalData?.category2) && (
              <>
                <FormControl className="my-2 w-100">
                  <InputLabel id="type-2">Type 2</InputLabel>
                  <Select
                    labelId="type-2"
                    id="type-2"
                    className="my-2"
                    value={
                      selectedType2
                        ? selectedType2
                        : product.additionalData?.type2
                        ? product.additionalData?.type2._id
                        : ""
                    }
                    onChange={(event) => {
                      setProduct({ ...product, type2: event.target.value });
                      setSelectedType2(event.target.value);
                    }}
                    placeholder="Seleccione un type 2..."
                    MenuProps={{
                      PaperProps: {
                        style: {
                          zIndex: 9999,
                        },
                      },
                    }}
                  >
                    {types
                      .filter((item) =>
                        item.category === selectedCategory2
                          ? selectedCategory2
                          : product.additionalData?.category2?._id
                      )
                      .map((type) => (
                        <MenuItem key={type._id} value={type._id}>
                          {getNonEmpty(type.fullName)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </>
            )}
            <FormControl className="my-2 w-100">
              <InputLabel id="brands">Brands</InputLabel>
              <Select
                labelId="brands"
                id="brands"
                className="my-2"
                value={product?.brand || ""}
                onChange={handleChange("brand")}
                placeholder="Seleccione una brand..."
                MenuProps={{
                  PaperProps: {
                    style: {
                      zIndex: 9999,
                    },
                  },
                }}
              >
                {brands?.map((brand) => (
                  <MenuItem key={brand._id} value={brand._id}>
                    {brand.fullName.replace(/\s+$/, (match) =>
                      "*".repeat(match.length)
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <FormControl className="my-2 w-100">
              <InputLabel id="related-Products">Related Products</InputLabel>
              <Select
                labelId="related-products"
                id="related-products"
                multiple
                className="my-2"
                value={product?.relatedProducts || []}
                onChange={handleChange("relatedProducts")}
                options={allProducts}
                placeholder="Seleccione los productos relacionados.."
                MenuProps={{
                  PaperProps: {
                    style: {
                      zIndex: 9999,
                    },
                  },
                }}
              >
                {allProducts?.map((product) => (
                  <MenuItem key={product._id} value={product._id}>
                    {getNonEmpty(product.fullName)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <FormGroup>
              {Object.keys(selectedPlatforms).map((platform) => (
                <div key={platform}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedPlatforms[platform]}
                        onChange={handlePlatformChange(platform)}
                        name={platform}
                      />
                    }
                    label={platform.charAt(0).toUpperCase() + platform.slice(1)}
                  />
                  {selectedPlatforms[platform] && (
                    <TextField
                      label={`${platform.charAt(0).toUpperCase() +
                        platform.slice(1)} URL`}
                      value={
                        (product?.socialMedia &&
                          product.socialMedia[platform]) ||
                        ""
                      }
                      onChange={handleLinkChange(platform)}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </div>
              ))}
            </FormGroup>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={product.active}
                  onChange={() =>
                    setProduct({ ...product, active: !product.active })
                  }
                  name="checkActive"
                />
              }
              label="Activo"
            />
          </CardBody>
        </Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => history.push("/products")}
            variant="outlined"
            style={{ marginRight: "20px" }}
          >
            Atrás
          </Button>
          <Button
            onClick={() => saveProduct()}
            variant="outlined"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Guardar product
          </Button>
          {productId && (
            <>
              <MuiThemeProvider theme={theme}>
                <Button
                  onClick={() => setOpenConfirmDialog(true)}
                  variant="outlined"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                >
                  Eliminar product
                </Button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "auto",
                  }}
                ></div>
              </MuiThemeProvider>

              <ConfirmDialog
                title={"¿Estás seguro de que quieres eliminar este product?"}
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                  deleteProduct(productId)
                    .then((res) => {
                      if (res.status === 204 || res.status === 200) {
                        alertSuccess({
                          title: "¡Eliminado!",
                          customMessage: "Product eliminado correctamente",
                        });
                        history.push("/products");
                      }
                    })
                    .catch((error) => {
                      alertError({
                        error: error,
                        customMessage: "No se ha podido eliminar el product.",
                      });
                    });
                }}
              />
            </>
          )}
        </div>
      </>
    );
}
