import { uploadSingleFile } from "../files";
import { API, authClient } from "../index";

// Get all articles
export const getProducts = () => {
  return authClient().get(`${API}/product?getDisabled=true`);
};

// Get article by id
export const getProductById = (id) => {
  return authClient().get(`${API}/product/${id}`);
};

// Delete article
export const deleteProduct = (id) => {
  return authClient().delete(`${API}/product/${id}`);
};

// Create article
export const postProduct = async (
  product,
  mainImg,
  blockImages,
  footerImage,
  pdfFile
) => {
  if (mainImg) {
    const response = await uploadSingleFile(mainImg, "products");
    product.mainImage = response.data.fileURL;
  }
  if (pdfFile) {
    const response = await uploadSingleFile(pdfFile, "products");
    product.pdfFile = response.data.fileURL;
  }
  const nonEmptyBlocks = blockImages?.filter((block) => block.image !== "");
  if (nonEmptyBlocks?.length > 0) {
    const uploadPromises = blockImages.map((block) => {
      if (block.image !== "" && block.image.type)
        return uploadSingleFile(block.image, "products");
    });
    const responses = await Promise.all(uploadPromises);
    console.log("RESPONSES: ", responses);
    const updatedBlocks = responses.map((response, index) => {
      if (!response) {
        return {
          image: blockImages[index].image ? blockImages[index].image : "",
          text: blockImages[index].text ? blockImages[index].text : "",
        };
      }
      return {
        image: response.data.fileURL,
        text: blockImages[index].text,
      };
    });

    // Update product.blocks with the updated blocks
    product.blocks = updatedBlocks;
  }

  return authClient().post(`${API}/product`, product);
};

// Update article
export const updateProduct = async (
  id,
  product,
  mainImg,
  blockImages,
  footerImage,
  pdfFile
) => {
  if (mainImg) {
    const response = await uploadSingleFile(mainImg, "products");
    product.mainImage = response.data.fileURL;
  }
  if (pdfFile) {
    const response = await uploadSingleFile(pdfFile, "products");
    product.pdfFile = response.data.fileURL;
  }
  if (footerImage) {
    const response = await uploadSingleFile(footerImage, "products");
    product.footerImage = response.data.fileURL;
  }
  const nonEmptyBlocks = blockImages?.filter((block) => block.image !== "");
  const nonEmptyBlocks2 = blockImages?.filter((block) => block.text !== "");
  if (nonEmptyBlocks?.length > 0) {
    const uploadPromises = blockImages.map((block) => {
      if (block.image !== "" && block.image.type)
        return uploadSingleFile(block.image, "products");
    });
    const responses = await Promise.all(uploadPromises);
    console.log("RESPONSES: ", responses);
    const updatedBlocks = responses.map((response, index) => {
      if (!response) {
        return {
          image: blockImages[index].image ? blockImages[index].image : "",
          text: blockImages[index].text ? blockImages[index].text : "",
        };
      }
      return {
        image: response.data.fileURL,
        text: blockImages[index].text,
      };
    });

    // Update product.blocks with the updated blocks
    product.blocks = updatedBlocks;
  } else if (nonEmptyBlocks2?.length > 0) {
    product.blocks = product.blocks.map((block, index) => ({
      ...block,
      text: blockImages[index].text,
    }));
  }

  console.log("FINAL_PRODUCT: ", product);
  return authClient().put(`${API}/product/${id}`, product);
};

const checkBlocksAreEmpty = () => {};

// Count all products
export const countProducts = () => {
  return authClient().get(`${API}/product/count`);
};

// Change status article
export const changeStatusProduct = async (id, active) => {
  return authClient().put(`${API}/product/change-status/${id}`, { active });
};
