import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  getCategories,
  deleteCategory,
  changeStatusCategory,
  updateCategoriesOrder,
} from "../../../../api/category";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import { shallowEqual, useSelector } from "react-redux";
import { getNonEmpty } from "../../../../utils/helpers";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

function getData(categories) {
  let data = [];
  for (let i = 0; i < categories.length; ++i) {
    const elem = {};
    elem.name = getNonEmpty(categories[i].name);
    elem.createdAt = categories[i].createdAt;
    elem.id = categories[i]._id;
    elem.active = categories[i].active;
    data = data.concat(elem);
  }
  return data;
}

export default function CategoriesPage() {
  const [data, setData] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  const handleSort = async (direction, cell) => {
    const currentIndex = data.findIndex((item) => item.id === cell);
    let swapIndex = -1;
    if (direction === "up" && currentIndex > 0) {
      swapIndex = currentIndex - 1;
    } else if (direction === "down" && currentIndex < data.length - 1) {
      swapIndex = currentIndex + 1;
    }

    if (swapIndex !== -1) {
      let newData = [...data];
      [newData[currentIndex], newData[swapIndex]] = [
        newData[swapIndex],
        newData[currentIndex],
      ];
      updateCategoriesOrder(
        newData[currentIndex].id,
        newData[swapIndex].id
      ).then((res) => {
        if (res.status === 200) setRefresh(true);
        else
          alertError({
            error: res,
            customMessage: "No se ha podido actualizar el orden.",
          });
      });
    }
  };

  function buttonFormatter(cell) {
    const elem = data.find((item) => item.id === cell);
    return (
      <>
        <Tooltip title="Editar">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-category/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {user?.role === "admin" && (
          <>
            <Tooltip title={elem?.active ? "Desactivar" : "Activar"}>
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setCategoryId(elem);
                  setOpenConfirmDialog(1);
                }}
              >
                {elem?.active ? (
                  <ToggleOffIcon />
                ) : (
                  <ToggleOnIcon style={{ color: "red" }} />
                )}
              </Button>
            </Tooltip>
            <Tooltip title="Eliminar">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setCategoryId(cell);
                  setOpenConfirmDialog(2);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Move up">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => handleSort("up", cell)}
              >
                <ArrowUpwardIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Move down">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => handleSort("down", cell)}
              >
                <ArrowDownwardIcon />
              </Button>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  const columns = [
    { dataField: "name", text: "Nombre", sort: true },
    {
      dataField: "createdAt",
      text: "Fecha de creación",
      formatter: dateFormatter,
      sort: true,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    getCategories()
      .then((res) => {
        if (res.status === 200) {
          const sortedData = res.data.sort((a, b) => a.order - b.order);
          setData(getData(sortedData));
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener las categories.",
        });
      });
  }, [refresh]);

  return (
    <>
      <Card>
        <CardHeader title="Lista de Categories">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-category")}
            >
              Añadir nueva
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={data} columns={columns} />
          <ConfirmDialog
            title={`¿Estás seguro de que quieres ${
              categoryId?.active ? "desactivar" : "activar"
            } esta category?`}
            open={openConfirmDialog === 1}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              changeStatusCategory(categoryId.id, !categoryId?.active)
                .then((res) => {
                  if (res.status === 200) {
                    alertSuccess({
                      title: `${
                        categoryId?.active ? "¡Desactivada!" : "¡Activada!"
                      }`,
                      customMessage: `Category ${
                        categoryId?.active ? "desactivada" : "activada"
                      } correctamente`,
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: `No se ha podido ${
                      categoryId?.active ? "desactivar" : "activar"
                    } esta category.`,
                  });
                });
            }}
          />
          <ConfirmDialog
            title={"¿Estás seguro de que quieres eliminar esta category?"}
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteCategory(categoryId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "¡Eliminada!",
                      customMessage: "Category eliminada correctamente.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "No se ha podido eliminar la category.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
