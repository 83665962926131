import { /* baseClient, */ authClient, API } from "../index";
import { uploadSingleFile } from "../files";

// Get all formats
export const getFormats = () => {
  return authClient().get(`${API}/format`);
};

// Get format by id
export const getFormatById = (id) => {
  return authClient().get(`${API}/format/${id}`);
};

// Delete format
export const deleteFormat = (id) => {
  return authClient().delete(`${API}/format/${id}`);
};

// Create format
export const postFormat = async (format, mainImg, miniImg) => {
  if (mainImg) {
    const response2 = await uploadSingleFile(mainImg, "formats");
    format.mainImg = response2.data.fileURL;
  }
  if (miniImg) {
    const response = await uploadSingleFile(miniImg, "formats");
    format.miniImg = response.data.fileURL;
  }
  return authClient().post(`${API}/format`, format);
};

// Update format
export const updateFormat = async (id, format, mainImg, miniImg) => {
  if (mainImg) {
    const response2 = await uploadSingleFile(mainImg, "formats");
    format.mainImg = response2.data.fileURL;
  }
  if (miniImg) {
    const response = await uploadSingleFile(miniImg, "formats");
    format.miniImg = response.data.fileURL;
  }
  return authClient().put(`${API}/format/${id}`, format);
};

// Count all types
export const countFormats = () => {
  return authClient().get(`${API}/format/count`);
};

export const updateFormatsOrder = (id, id2) => {
  return authClient().post(`${API}/format/swapFormats`, {
    format1Id: id,
    format2Id: id2,
  });
};
