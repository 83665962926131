import { API, authClient } from "../index";
import { uploadSingleFile } from "../files";

// Get all categories
export const getCategories = () => {
  return authClient().get(`${API}/category?getDisabled=true`);
};

// Get category by id
export const getCategoryById = (id) => {
  return authClient().get(`${API}/category/${id}`);
};

// Delete category
export const deleteCategory = (id) => {
  return authClient().delete(`${API}/category/${id}`);
};

// Create category
export const postCategory = async (category, image) => {
  if (image) {
    const response = await uploadSingleFile(image, "categories");
    category.image = response.data.fileURL;
  }
  return authClient().post(`${API}/category`, category);
};

// Update category
export const updateCategory = async (id, category, image) => {
  if (image) {
    const response = await uploadSingleFile(image, "categories");
    category.image = response.data.fileURL;
  }
  return authClient().put(`${API}/category/${id}`, category);
};

// Count all categories
export const countCategories = () => {
  return authClient().get(`${API}/category/count`);
};

// Change status category
export const changeStatusCategory = async (id, active) => {
  return authClient().put(`${API}/category/change-status/${id}`, { active });
};

export const updateCategoriesOrder = (id, id2) => {
  return authClient().post(`${API}/category/swapCategories`, {
    category1Id: id,
    category2Id: id2,
  });
};
