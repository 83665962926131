import React, { useEffect, useState } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from '../../../../_metronic/_partials/controls';
import Table, {
	dateFormatter,
	buttonsStyle
} from '../../../components/tables/table';
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog';
import { getProducts, deleteProduct, changeStatusProduct } from '../../../../api/product';
import { Button, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { alertError, alertSuccess } from '../../../../utils/logger';
import { useHistory } from 'react-router-dom';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import { shallowEqual, useSelector } from 'react-redux';
import { getBrands } from '../../../../api/brand'
import { getCategories } from '../../../../api/category'
import { getTypes } from '../../../../api/type'
import FiltersCard from '../../../components/filters/Filter';
import { getNonEmpty } from '../../../../utils/helpers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getData(products, brands, categories, types) {
	let data = [];
	for (let i = 0; i < products.length; ++i) {
		const brand = products[i].brand ? brands.find(cat => cat._id === products[i].brand) : null
		const type = products[i].type ? types.find(cat => cat._id === products[i].type) : null
		const category = type ? categories.find(cat => cat._id === type.category) : null

		const elem = {};
		elem.name = getNonEmpty(products[i].fullName);
		elem.category = category ? getNonEmpty(category.name) : '---'
		elem.type = type ? getNonEmpty(type.fullName) : '---'
		elem.brand = brand ? brand.fullName : '---'
		elem.createdAt = products[i].createdAt;
		elem.id = products[i]._id;
		elem.active = products[i].active;
		data = data.concat(elem);
	}
	return data;
}

const initialFilters = {
	category: [],
  	type: [],
	brand: [],
	cp: null
}

export default function ProductsPage() {
	const [data, setData] = useState([]);
	const [productId, setProductId] = useState(null);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [categories, setCategories] = useState([])
  	const [types, setTypes] = useState([])
	const [brands, setBrands] = useState([])
  	const [filterOptions, setFilterOptions] = useState(initialFilters)
	const [collapsed, setCollapsed] = useState(true)
	const history = useHistory();
	const user = useSelector((store) => store.authentication?.user, shallowEqual)

	function buttonFormatter(cell) {
		const elem = data.find(item => item._id === cell)
		return (
			<>
				<Tooltip title="Editar">
					<Button
						style={buttonsStyle}
						size='small'
						onClick={() => history.push('/edit-product/' + cell)}>
						<EditIcon />
					</Button>
				</Tooltip>
				{user?.role === 'admin' && <>
					<Tooltip title={elem?.active ? "Desactivar" : "Activar"}>
						<Button
							style={buttonsStyle} size="small"
							onClick={() => {
								setProductId(elem)
								setOpenConfirmDialog(1)
							}}>
							{elem?.active ? <ToggleOffIcon/> : <ToggleOnIcon style={{color :'red'}}/>}
						</Button>
					</Tooltip>
					<Tooltip title="Eliminar">
						<Button
							style={buttonsStyle}
							size='small'
							onClick={() => {
								setProductId(cell);
								setOpenConfirmDialog(2);
							}}>
							<DeleteIcon />
						</Button>
					</Tooltip>
				</>}
			</>
		);
	}

	const columns = [
		{ dataField: 'name', text: 'Nombre', sort: true },
		{ dataField: 'category', text: 'Category', sort: true },
		{ dataField: 'type', text: 'Type', sort: true },
		{ dataField: 'brand', text: 'Brand', sort: true },
		{
			dataField: 'createdAt',
			text: 'Fecha de creación',
			formatter: dateFormatter,
			sort: true,
		},
		{ dataField: 'id', text: '', formatter: buttonFormatter },
	];

	useEffect(() => {
		getBrands().then((resp) => {
			if (resp.status === 200) {
				setBrands(resp.data)
				setRefresh(false)
			}
		  }).catch(error => {
			alertError({ error: error, customMessage: 'No se han podido obtener las brands.' })
		  })
		getTypes().then(respP => {
			if (respP.status === 200) {
				setTypes(respP.data)
				setRefresh(false)
			}
		}).catch(error => {
			alertError({ error: error, customMessage: 'No se han podido obtener las types.' })
		})
		getCategories().then(respC => {
			if (respC.status === 200) {
				setCategories(respC.data)
				setRefresh(false)
			}
		}).catch(error => {
			alertError({ error: error, customMessage: 'No se han podido obtener las categories.' })
		})
		getProducts().then((res) => {
			if (res.status === 200) {
				setData(res.data)
				setFilteredData(res.data)
				setRefresh(false)
			}
		})
		.catch((error) => {
			alertError({ error: error, customMessage: 'No se han podido obtener los products.' });
		});
	}, [refresh]);

	const handleSearch = async () => {
		if (!data.length) return
		setFilteredData(data.filter(item => {
		  	let filter = true
			if (filterOptions.category && filterOptions.category.length) filter = filter && filterOptions.category.includes(types.find(c => c._id === (brands.find(c => c._id === item.brand)?.type))?.category)
			if (filterOptions.type && filterOptions.type.length) filter = filter && filterOptions.type.includes(item.types)
			if (filterOptions.brand && filterOptions.brand.length) filter = filter && filterOptions.brand.includes(item.brand)
			if (filterOptions.cp && filterOptions.cp.length) filter = filter && item.postalCode.includes(filterOptions.cp)
			if (filter) return item
			return false
		}))
	}

	const handleClearFilters = () => {
		setFilterOptions(initialFilters)
		setRefresh(true)
	}

	const handleChange = (element) => (event) => {
		setFilterOptions({ ...filterOptions, [element]: event.target.value });
	}; 

	const renderFiltersContent = () => {
		return <>
			<br/>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Category</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.category || ''}
					multiple
					onChange={handleChange('category')}
					MenuProps={MenuProps}
				>
				{categories?.map((option) => (
					<MenuItem key={option._id} value={option._id}>{option.name}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
			<br/>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.type || ''}
						multiple
					onChange={handleChange('type')}
					MenuProps={MenuProps}
				>
				{types?.map((option) => (
					<MenuItem key={option._id} value={option._id}>{option.fullName}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br/>
			<br/>
			<FormControl style={{ width: '100%' }}>
				<InputLabel id="demo-simple-select-standard-label">Brand</InputLabel>
				<Select
					labelId="demo-simple-select-standard-label"
					id="demo-simple-select-standard"
					value={filterOptions.brand || ''}
					multiple
					onChange={handleChange('brand')}
					MenuProps={MenuProps}
				>
				{brands?.map((option) => (
					<MenuItem key={option._id} value={option._id}>{option.fullName}</MenuItem>
				))}
				</Select>
			</FormControl>
			<br />
			<br />
		</>
	}

	return (
		<>
			<Card>
				<CardHeader title='Lista de Products'>
					<CardHeaderToolbar>
						<button
							type='button'
							className='btn btn-primary'
							onClick={() => history.push('/edit-product')}>
							Añadir nuevo
						</button>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<FiltersCard 
						filtersContent={renderFiltersContent}
						collapsed={collapsed} 
						setCollapsed={setCollapsed}
						handleClearFilters={handleClearFilters}
						handleSearch={handleSearch}
					/>

					<Table
						data={getData(filteredData, brands, categories, types)}
						columns={columns}
					/>

					<ConfirmDialog
						title={`¿Estás seguro de que quieres ${productId?.active ? 'desactivar' : 'activar'} este product?`}
						open={openConfirmDialog === 1}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							changeStatusProduct(productId.id, !productId?.active)
								.then((res) => {
									if (res.status === 200) {
										alertSuccess({ title: `${productId?.active ? '¡Desactivado!' : '¡Activado!'}`, customMessage: `Product ${productId?.active ? 'desactivado' : 'activado'} correctamente` })
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({ error: error, customMessage: `No se ha podido ${productId?.active ? 'desactivar' : 'activar'} este product.` })
								});
						}}
					/>
					<ConfirmDialog
						title={'¿Estás seguro de que quieres eliminar este product?'}
						open={openConfirmDialog === 2}
						setOpen={setOpenConfirmDialog}
						onConfirm={() => {
							deleteProduct(productId)
								.then((res) => {
									if (res.status === 204 || res.status === 200) {
										alertSuccess({
											title: '¡Eliminado!',
											customMessage: 'Product eliminado correctamente.',
										});
										setRefresh(true);
									}
								})
								.catch((error) => {
									alertError({
										error: error,
										customMessage: 'No se ha podido eliminar el product.',
									});
								});
						}}
					/>
				</CardBody>
			</Card>
		</>
	);
}
