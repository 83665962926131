import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteBrand,
  getBrandById,
  postBrand,
  updateBrand,
} from "../../../../api/brand";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Editor from "../../../components/editor/Editor";
import { shallowEqual, useSelector } from "react-redux";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { SERVER_URL } from "../../../../api/index";
import { Visibility } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { buttonsStyle } from "../../../components/tables/table";
import { formatFileName } from "../../../../utils/helpers";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyBrand() {
  return {
    fullName: "",
    logo: "",
    mainImg: "",
    video: {},
    blocks: [
      { image: "", text: {}, button: "" },
      { image: "", text: {}, button: "" },
      { image: "", text: {}, button: "" },
      { image: "", text: {}, button: "" },
      { image: "", text: {}, button: "" },
      { image: "", text: {}, button: "" },
    ],
    mundotequila: true,
  };
}

export default function EditBrandsPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [brand, setBrand] = useState(getEmptyBrand());
  const [selectedImage, setSelectedImage] = useState(null);
  const [mainImg, setMainImg] = useState(null);
  const [video, setVideo] = useState({});
  const [openPreviewDialogLogo, setOpenPreviewDialogLogo] = useState(false);
  const [openPreviewDialogMainImage, setOpenPreviewDialogMainImage] = useState(
    false
  );
  const [openPreviewVideo, setOpenPreviewVideo] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const brandId = useParams().id;
  const history = useHistory();
  const [blockImages, setBlockImages] = useState(
    { image: "", text: {}, button: "" },
    { image: "", text: {}, button: "" },
    { image: "", text: {}, button: "" },
    { image: "", text: {}, button: "" },
    { image: "", text: {}, button: "" },
    { image: "", text: {}, button: "" }
  );
  const [openPreviewDialogBlock1, setOpenPreviewDialogBlock1] = useState(false);
  const [openPreviewDialogBlock2, setOpenPreviewDialogBlock2] = useState(false);
  const [openPreviewDialogBlock3, setOpenPreviewDialogBlock3] = useState(false);
  const [openPreviewDialogBlock4, setOpenPreviewDialogBlock4] = useState(false);
  const [openPreviewDialogBlock5, setOpenPreviewDialogBlock5] = useState(false);
  const [openPreviewDialogBlock6, setOpenPreviewDialogBlock6] = useState(false);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!brandId) {
      disableLoadingData();
      return;
    }
    getBrandById(brandId)
      .then((res) => {
        if (res.status === 200) {
          const user = res.data;
          delete user.password;
          setBrand(user);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido obtener brand.",
        });
        history.push("/brands");
      });
  }, [brandId, disableLoadingData, history]);

  function saveBrand() {
    if (!brandId) {
      postBrand(brand, selectedImage, mainImg, video, blockImages)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Brand creada correctamente.",
            });
            history.push("/brands");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se ha podido guardar brand.",
          });
        });
    } else {
      updateBrand(brandId, brand, selectedImage, mainImg, video, blockImages)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Los cambios se han guardado correctamente.",
            });
            history.push("/brands");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se han podido guardar los cambios.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setBrand({ ...brand, [element]: event.target.value });
  };

  const handleChangeBlock = (element, index) => (event) => {
    const blockIndex = parseInt(element.split("_")[1]);
    const newBlocks = [...brand?.blocks];
    if (element.startsWith("blockImage")) {
      newBlocks[blockIndex].image = event.target.files[0];
    } else {
      newBlocks[blockIndex].text = event.target.value;
    }
    setBrand({ ...brand, blocks: newBlocks });
  };

  useEffect(() => {
    console.log("BRAND: ", brand.mainImg);
  }, [brand]);

  const isBlockImagesEmpty = () => {
    if (
      typeof blockImages === "object" &&
      blockImages !== null &&
      !Array.isArray(blockImages)
    ) {
      return (
        blockImages.image === "" &&
        Object.keys(blockImages.text).length === 0 &&
        Object.keys(blockImages.button).length === 0
      );
    }
    if (Array.isArray(blockImages)) {
      return blockImages.every(
        (block) =>
          block.image === "" &&
          Object.keys(block.text).length === 0 &&
          Object.keys(block.button).length === 0
      );
    }
    return true;
  };

  const renderMultilanguageTabContent = (lang) => {
    return (
      <>
        <br />
        <label htmlFor={"upload-video"}>
          <input
            style={{ display: "none" }}
            id={"upload-video"}
            name={"upload-video"}
            type="file"
            accept={"video/*"}
            onChange={(e) => {
              const originalFile = e.target.files[0];
              const formattedName = formatFileName(originalFile.name);
              const blob = new Blob([originalFile], {
                type: originalFile.type,
              });
              const newFile = new File([blob], formattedName, {
                type: originalFile.type,
              });

              const updatedVideo = { ...video };
              updatedVideo[lang] = newFile;

              setVideo(updatedVideo);
            }}
          />
          <Button
            style={{ marginRight: "15px" }}
            color="secondary"
            component="span"
            variant="outlined"
          >
            {video[lang] ||
            (brand?.video?.[lang] && brand?.video?.[lang] !== "")
              ? "Change video"
              : "Upload video"}
          </Button>
        </label>
        {(video[lang] ||
          (brand?.video?.[lang] && brand?.video?.[lang] !== "")) && (
          <>
            <PreviewDialog
              title={"Preview main Video"}
              open={openPreviewVideo}
              setOpen={setOpenPreviewVideo}
              src={
                video[lang]
                  ? URL.createObjectURL(video[lang])
                  : `${SERVER_URL}/${brand?.video?.[lang]}`
              }
            />
            <span>
              {video[lang]
                ? video?.[lang].name
                : brand?.video?.[lang] && brand?.video?.[lang] !== ""
                ? brand?.video?.[lang].split(/-(.*)/s)[1]
                : ""}
            </span>
            <Tooltip title={"Preview Video"}>
              <Button
                size="small"
                onClick={() => setOpenPreviewVideo(true)}
                style={{ ...buttonsStyle, marginRight: "15px" }}
              >
                <Visibility />
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                size="small"
                onClick={() => {
                  if (video[lang]) {
                    const updatedVideo = { ...video };
                    delete updatedVideo[lang];
                    setVideo(updatedVideo);
                  } else {
                    setBrand({
                      ...brand,
                      video: { ...brand.video, [lang]: "" },
                    });
                  }
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </>
        )}
        <br />
        {[0, 1, 2, 3, 4, 5].map((index) => (
          <div key={index}>
            <label htmlFor={`upload-block-image-${index}`}>
              <input
                style={{ display: "none" }}
                id={`upload-block-image-${index}`}
                name={`upload-block-image-${index}`}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  let newBlockImages;

                  if (!isBlockImagesEmpty()) newBlockImages = [...blockImages];
                  else
                    newBlockImages =
                      brand?.blocks && brand?.blocks.length > 0
                        ? [...brand?.blocks]
                        : [
                            { image: "", text: {}, button: {} },
                            { image: "", text: {}, button: {} },
                            { image: "", text: {}, button: {} },
                            { image: "", text: {}, button: {} },
                            { image: "", text: {}, button: {} },
                            { image: "", text: {}, button: {} },
                          ];

                  const originalFile = e.target.files[0];
                  const formattedName = formatFileName(originalFile.name);

                  const blob = new Blob([originalFile], {
                    type: originalFile.type,
                  });

                  const newFile = new File([blob], formattedName, {
                    type: originalFile.type,
                  });
                  console.log("NEW FILE: ", newFile);
                  console.log("NEW BLOCK IMAGES: ", newBlockImages);
                  newBlockImages[index].image = newFile;
                  console.log("NEW BLOCK IMAGES: ", newBlockImages);
                  setBlockImages(newBlockImages);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {(blockImages && blockImages[index]) ||
                (brand?.blocks[index]?.image &&
                  brand?.blocks[index]?.image !== "")
                  ? `Change block ${index + 1} image`
                  : `Upload block ${index + 1} image`}
              </Button>
            </label>
            {((blockImages && blockImages[index]) ||
              (brand?.blocks[index]?.image &&
                brand?.blocks[index]?.image !== "")) && (
              <>
                <Tooltip title={`Preview block ${index + 1} Image`}>
                  <Button
                    size="small"
                    onClick={() => {
                      if (index === 0) setOpenPreviewDialogBlock1(true);
                      if (index === 1) setOpenPreviewDialogBlock2(true);
                      if (index === 2) setOpenPreviewDialogBlock3(true);
                      if (index === 3) setOpenPreviewDialogBlock4(true);
                      if (index === 4) setOpenPreviewDialogBlock5(true);
                      if (index === 5) setOpenPreviewDialogBlock6(true);
                    }}
                    style={{ marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      let newBlockImages = [
                        { image: "", text: {}, button: {} },
                        { image: "", text: {}, button: {} },
                        { image: "", text: {}, button: {} },
                        { image: "", text: {}, button: {} },
                        { image: "", text: {}, button: {} },
                        { image: "", text: {}, button: {} },
                      ];
                      console.log(blockImages, newBlockImages);
                      if (!isBlockImagesEmpty())
                        newBlockImages = [...blockImages];
                      else newBlockImages = [...brand?.blocks];
                      newBlockImages[index].image = "";
                      if (brand && brand.blocks && brand.blocks[index])
                        brand.blocks[index].image = "";

                      setBlockImages(newBlockImages);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
                <PreviewDialog
                  title={"Preview logo"}
                  open={
                    index === 0
                      ? openPreviewDialogBlock1
                      : index === 1
                      ? openPreviewDialogBlock2
                      : index === 2
                      ? openPreviewDialogBlock3
                      : index === 3
                      ? openPreviewDialogBlock4
                      : index === 4
                      ? openPreviewDialogBlock5
                      : openPreviewDialogBlock6
                  }
                  setOpen={
                    index === 0
                      ? setOpenPreviewDialogBlock1
                      : index === 1
                      ? setOpenPreviewDialogBlock2
                      : index === 2
                      ? setOpenPreviewDialogBlock3
                      : index === 3
                      ? setOpenPreviewDialogBlock4
                      : index === 4
                      ? setOpenPreviewDialogBlock5
                      : setOpenPreviewDialogBlock6
                  }
                  src={
                    blockImages &&
                    (blockImages[index]?.image instanceof Blob ||
                      blockImages[index]?.image instanceof File)
                      ? URL.createObjectURL(blockImages[index]?.image)
                      : blockImages && blockImages[index]?.image
                      ? `${SERVER_URL}/${blockImages[index]?.image}`
                      : `${SERVER_URL}/${brand?.blocks[index]?.image}`
                  }
                />
                <span>
                  {blockImages && blockImages[index]?.image?.name
                    ? blockImages[index]?.image?.name
                    : brand?.blocks[index]?.image &&
                      brand?.blocks[index]?.image !== ""
                    ? brand?.blocks[index]?.image.split(/-(.*)/s)[1]
                    : ""}
                </span>
              </>
            )}
            <br />
            <br />
            <Editor
              label={`Block ${index + 1} Text`}
              body={
                blockImages &&
                blockImages[index] &&
                blockImages[index].text &&
                blockImages[index].text[lang] !== undefined
                  ? blockImages[index].text[lang]
                  : brand &&
                    brand.blocks &&
                    brand.blocks[index] &&
                    brand.blocks[index].text &&
                    brand.blocks[index].text[lang] !== undefined
                  ? brand.blocks[index].text[lang]
                  : ""
              }
              setBody={(e) => {
                let updatedBlockTexts;
                if (!isBlockImagesEmpty()) {
                  updatedBlockTexts = [...blockImages];
                } else updatedBlockTexts = [...brand?.blocks];
                let updatedText = updatedBlockTexts[index]?.text || {};
                updatedText[lang] = e;
                updatedBlockTexts[index] = {
                  ...updatedBlockTexts[index],
                  text: updatedText,
                };
                setBlockImages(updatedBlockTexts);
              }}
              className="max-height"
              placeholder={"Enter brand description here..."}
            />
            <br />
            <br />
            <TextField
              id={`Block ${index} Button`}
              label={`Block ${index + 1} Button`}
              value={
                blockImages &&
                blockImages[index] &&
                blockImages[index].button &&
                blockImages[index].button[lang] !== undefined
                  ? blockImages[index].button[lang]
                  : brand &&
                    brand.blocks &&
                    brand.blocks[index] &&
                    brand.blocks[index].button &&
                    brand.blocks[index].button[lang] !== undefined
                  ? brand.blocks[index].button[lang]
                  : ""
              }
              onChange={(e) => {
                let updatedBlockButtons;
                if (
                  blockImages &&
                  blockImages[index] &&
                  blockImages[index].button &&
                  blockImages[index].button[lang] !== undefined
                ) {
                  updatedBlockButtons = [...blockImages];
                } else updatedBlockButtons = [...brand?.blocks];
                console.log(updatedBlockButtons);
                let updatedButton = updatedBlockButtons[index]?.button || {};
                updatedButton[lang] = e.target.value;
                updatedBlockButtons[index] = {
                  ...updatedBlockButtons[index],
                  button: updatedButton,
                };
                console.log("NEW BLOCKS: ", updatedBlockButtons);
                setBlockImages(updatedBlockButtons);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <br />
          </div>
        ))}
      </>
    );
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Editar brand"></CardHeader>
          <CardBody>
            <TextField
              id={`fullName`}
              label="Brand"
              value={brand.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <label htmlFor={"upload-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-image"}
                name={"upload-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  const originalFile = e.target.files[0];
                  const formattedName = formatFileName(originalFile.name);
                  const blob = new Blob([originalFile], {
                    type: originalFile.type,
                  });
                  const newFile = new File([blob], formattedName, {
                    type: originalFile.type,
                  });
                  setSelectedImage(newFile);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {selectedImage || (brand.logo && brand.logo !== "")
                  ? "Change logo"
                  : "Upload logo"}
              </Button>
            </label>
            {(selectedImage || (brand.logo && brand.logo !== "")) && (
              <>
                <PreviewDialog
                  title={"Preview logo"}
                  open={openPreviewDialogLogo}
                  setOpen={setOpenPreviewDialogLogo}
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : `${SERVER_URL}/${brand.logo}`
                  }
                />
                <span>
                  {selectedImage
                    ? selectedImage?.name
                    : brand.logo && brand.logo !== ""
                    ? brand.logo?.split(/-(.*)/s)[1]
                    : ""}
                </span>
                <Tooltip title={"Preview logo"}>
                  <Button
                    size="small"
                    onClick={() => setOpenPreviewDialogLogo(true)}
                    style={{ ...buttonsStyle, marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      if (selectedImage) setSelectedImage(null);
                      else setBrand({ ...brand, logo: "" });
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            <br />
            {/* PART DE LA IMATGE PRINCIPAL */}
            <label htmlFor={"upload-main-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-main-image"}
                name={"upload-main-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  const originalFile = e.target.files[0];
                  const formattedName = formatFileName(originalFile.name);
                  const blob = new Blob([originalFile], {
                    type: originalFile.type,
                  });
                  const newFile = new File([blob], formattedName, {
                    type: originalFile.type,
                  });
                  setMainImg(newFile);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {mainImg || (brand.mainImg && brand.mainImg !== "")
                  ? "Change main image"
                  : "Upload main image"}
              </Button>
            </label>
            {(mainImg || (brand.mainImg && brand.mainImg !== "")) && (
              <>
                <PreviewDialog
                  title={"Preview main Image"}
                  open={openPreviewDialogMainImage}
                  setOpen={setOpenPreviewDialogMainImage}
                  src={
                    mainImg
                      ? URL.createObjectURL(mainImg)
                      : `${SERVER_URL}/${brand.mainImg}`
                  }
                />
                <span>
                  {mainImg
                    ? mainImg?.name
                    : brand.mainImg && brand.mainImg !== ""
                    ? brand.mainImg?.split(/-(.*)/s)[1]
                    : ""}
                </span>
                <Tooltip title={"Preview main Image"}>
                  <Button
                    size="small"
                    onClick={() => setOpenPreviewDialogMainImage(true)}
                    style={{ ...buttonsStyle, marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      if (mainImg) setMainImg(null);
                      else setBrand({ ...brand, mainImg: "" });
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={brand.mundotequila}
                  onChange={() =>
                    setBrand({ ...brand, mundotequila: !brand.mundotequila })
                  }
                  name="checkMundotequila"
                />
              }
              label="Mundo Tequila"
            />
            <br />
            <MultilanguageTabBlock
              multilanguageTabContent={renderMultilanguageTabContent}
            />
            <br />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/brands")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Atrás
        </Button>
        <Button
          onClick={() => saveBrand()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Guardar brand
        </Button>
        {brandId && user?.role === "admin" && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Eliminar brand
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"¿Estás seguro de que quieres eliminar esta brand?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteBrand(brandId)
                  .then((res) => {
                    if (res.status === 204) {
                      alertSuccess({
                        title: "¡Eliminado!",
                        customMessage: "Brand eliminada correctamente",
                      });
                      history.push("/brands");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "No se ha podido eliminar la brand.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
