import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteDownload,
  getDownloadById,
  postDownload,
  updateDownload,
} from "../../../../api/download";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { shallowEqual, useSelector } from "react-redux";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { SERVER_URL } from "../../../../api/index";
import { Visibility } from "@material-ui/icons";
import { buttonsStyle } from "../../../components/tables/table";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyDownload() {
  return {
    fullName: "",
    file: "",
    download: true,
    order: 0,
  };
}

export default function EditDownloadsPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [download, setDownload] = useState(getEmptyDownload());
  const [selectedImage, setSelectedImage] = useState(null);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const downloadId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!downloadId) {
      disableLoadingData();
      return;
    }
    getDownloadById(downloadId)
      .then((res) => {
        if (res.status === 200) {
          const user = res.data;
          delete user.password;
          setDownload(user);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido obtener download.",
        });
        history.push("/downloads");
      });
  }, [downloadId, disableLoadingData, history]);

  function saveDownload() {
    if (!downloadId) {
      postDownload(download, selectedImage)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Download creada correctamente.",
            });
            history.push("/downloads");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se ha podido guardar download.",
          });
        });
    } else {
      updateDownload(downloadId, download, selectedImage)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Los cambios se han guardado correctamente.",
            });
            history.push("/downloads");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se han podido guardar los cambios.",
          });
        });
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (event.target.value === " ") return;
    if (lang) {
      if (!download[element]) download[element] = {};
      let newText = download[element];
      newText[lang] = event.target.value;
      setDownload({ ...download, [element]: newText });
    } else setDownload({ ...download, [element]: event.target.value });
  };

  const renderMultilanguageTabContent = (lang) => {
    return (
      <>
        <br />
        <TextField
          id={`fullName`}
          label="Nombre"
          value={(download.fullName && download.fullName[lang]) || ""}
          onChange={handleChange("fullName", lang)}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
          required
        />
        <label htmlFor={"upload-image"}>
          <input
            style={{ display: "none" }}
            id={"upload-image"}
            name={"upload-image"}
            type="file"
            accept={"pdf/*"}
            onChange={(e) => {
              setSelectedImage({ ...selectedImage, [lang]: e.target.files[0] });
            }}
          />
          <Button
            style={{ marginRight: "15px" }}
            color="secondary"
            component="span"
            variant="outlined"
          >
            {selectedImage?.[lang] ||
            (download.file?.[lang] && download.file?.[lang] !== "")
              ? "Change file"
              : "Upload file"}
          </Button>
        </label>
        {(selectedImage?.[lang] ||
          (download.file?.[lang] && download.file?.[lang] !== "")) && (
          <>
            <Tooltip title={"Preview file"}>
              <Button
                size="small"
                onClick={() => setOpenPreviewDialog(true)}
                style={{ ...buttonsStyle, marginRight: "15px" }}
              >
                <Visibility />
              </Button>
            </Tooltip>
            <PreviewDialog
              title={"Preview file"}
              open={openPreviewDialog}
              setOpen={setOpenPreviewDialog}
              src={
                selectedImage?.[lang]
                  ? URL.createObjectURL(selectedImage?.[lang])
                  : `${SERVER_URL}/${download.file?.[lang]}`
              }
            />
            <span>
              {selectedImage?.[lang]
                ? selectedImage?.[lang]?.name
                : download.file?.[lang] && download.file?.[lang] !== ""
                ? download.file?.[lang]?.split(/-(.*)/s)[1]
                : ""}
            </span>
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    console.log("DOWNLOAD: ", download);
  }, [download]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Editar download"></CardHeader>
          <CardBody>
            <MultilanguageTabBlock
              multilanguageTabContent={renderMultilanguageTabContent}
            />
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={download.download}
                  onChange={() =>
                    setDownload({ ...download, download: !download.download })
                  }
                  name="checkDownlaod"
                />
              }
              label="Aparece en la sección de descargas"
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/downloads")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Atrás
        </Button>
        <Button
          onClick={() => saveDownload()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Guardar download
        </Button>
        {downloadId && user?.role === "admin" && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Eliminar download
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"¿Estás seguro de que quieres eliminar esta download?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteDownload(downloadId)
                  .then((res) => {
                    if (res.status === 204) {
                      alertSuccess({
                        title: "¡Eliminado!",
                        customMessage: "Download eliminada correctamente",
                      });
                      history.push("/downloads");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "No se ha podido eliminar la download.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
