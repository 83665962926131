import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { deleteQR, getQRById, postQR, updateQR } from "../../../../api/QR";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import { checkIsEmpty } from "../../../../utils/helpers";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { SERVER_URL } from "../../../../api/index";
import { Visibility } from "@material-ui/icons";
import { buttonsStyle } from "../../../components/tables/table";
import { formatFileName } from "../../../../utils/helpers";
import DeleteIcon from "@material-ui/icons/Delete";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyQR() {
  return {
    QRId: "",
    redirectURL: "",
    active: true,
  };
}

export default function EditQRsPage() {
  const [QR, setQR] = useState(getEmptyQR());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const QRId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!QRId) {
      disableLoadingData();
      return;
    }
    getQRById(QRId)
      .then((res) => {
        if (res.status === 200) {
          setQR(res.data);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido obtener la QR.",
        });
        history.push("/QRs");
      });
  }, [QRId, disableLoadingData, history]);

  function saveQR() {
    if (checkIsEmpty(QR.QRId))
      return alertError({
        error: null,
        customMessage: "El nombre es requerido.",
      });
    if (!QRId) {
      postQR(QR)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "QR creada correctamente.",
            });
            history.push("/QRs");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se ha podido guarda la QR.",
          });
        });
    } else {
      updateQR(QRId, QR)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Los cambios se han guardado correctamente.",
            });
            history.push("/QRs");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se han podido guardar los cambios.",
          });
        });
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (event.target.value === " ") return;
    if (lang) {
      if (!QR[element]) QR[element] = {};
      let newText = QR[element];
      newText[lang] = event.target.value;
      setQR({ ...QR, [element]: newText });
    } else setQR({ ...QR, [element]: event.target.value });
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Editar QR"></CardHeader>
          <CardBody>
            <TextField
              label="QRId"
              value={QR.QRId}
              onChange={handleChange("QRId")}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              label="URL de redirección"
              value={QR.redirectURL}
              onChange={handleChange("redirectURL")}
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </CardBody>
        </Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => history.push("/QRs")}
            variant="outlined"
            style={{ marginRight: "20px" }}
          >
            Atrás
          </Button>
          <Button
            onClick={() => saveQR()}
            variant="outlined"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Guardar QR
          </Button>
          {QRId && (
            <>
              <MuiThemeProvider theme={theme}>
                <Button
                  onClick={() => setOpenConfirmDialog(true)}
                  variant="outlined"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                >
                  Eliminar QR
                </Button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "auto",
                  }}
                ></div>
              </MuiThemeProvider>

              <ConfirmDialog
                title={"¿Estás seguro de que quieres eliminar esta QR?"}
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                  deleteQR(QRId)
                    .then((res) => {
                      if (res.status === 204 || res.status === 200) {
                        alertSuccess({
                          title: "¡Eliminada!",
                          customMessage: "QR eliminada correctamente",
                        });
                        history.push("/QRs");
                      }
                    })
                    .catch((error) => {
                      alertError({
                        error: error,
                        customMessage: "No se ha podido eliminar la QR.",
                      });
                    });
                }}
              />
            </>
          )}
        </div>
      </>
    );
}
