import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteCategory,
  getCategoryById,
  postCategory,
  updateCategory,
} from "../../../../api/category";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import { checkIsEmpty } from "../../../../utils/helpers";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { SERVER_URL } from "../../../../api/index";
import { Visibility } from "@material-ui/icons";
import { buttonsStyle } from "../../../components/tables/table";
import { formatFileName } from "../../../../utils/helpers";
import DeleteIcon from "@material-ui/icons/Delete";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyCategory() {
  return {
    name: {},
    image: "",
    active: true,
  };
}

export default function EditCategoriesPage() {
  const [category, setCategory] = useState(getEmptyCategory());
  const [selectedImage, setSelectedImage] = useState(null);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const categoryId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!categoryId) {
      disableLoadingData();
      return;
    }
    getCategoryById(categoryId)
      .then((res) => {
        if (res.status === 200) {
          setCategory(res.data);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido obtener la category.",
        });
        history.push("/categories");
      });
  }, [categoryId, disableLoadingData, history]);

  function saveCategory() {
    if (checkIsEmpty(category.name))
      return alertError({
        error: null,
        customMessage: "El nombre es requerido.",
      });
    if (!categoryId) {
      postCategory(category, selectedImage)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Category creada correctamente.",
            });
            history.push("/categories");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se ha podido guarda la category.",
          });
        });
    } else {
      updateCategory(categoryId, category, selectedImage)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Los cambios se han guardado correctamente.",
            });
            history.push("/categories");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se han podido guardar los cambios.",
          });
        });
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (event.target.value === " ") return;
    if (lang) {
      if (!category[element]) category[element] = {};
      let newText = category[element];
      newText[lang] = event.target.value;
      setCategory({ ...category, [element]: newText });
    } else setCategory({ ...category, [element]: event.target.value });
  };

  const renderMultilanguageTabContent = (lang) => {
    return (
      <>
        <br />
        <TextField
          id={`name`}
          label="Nombre"
          value={(category.name && category.name[lang]) || ""}
          onChange={handleChange("name", lang)}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
          required
        />
      </>
    );
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Editar category"></CardHeader>
          <CardBody>
            <MultilanguageTabBlock
              multilanguageTabContent={renderMultilanguageTabContent}
            />
            <br />
            <label htmlFor={"upload-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-image"}
                name={"upload-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  const originalFile = e.target.files[0];
                  const formattedName = formatFileName(originalFile.name);
                  const blob = new Blob([originalFile], {
                    type: originalFile.type,
                  });
                  const newFile = new File([blob], formattedName, {
                    type: originalFile.type,
                  });
                  setSelectedImage(newFile);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {selectedImage || (category.image && category.image !== "")
                  ? "Change multimedia file"
                  : "Upload multimedia file"}
              </Button>
            </label>
            {(selectedImage || (category.image && category.image !== "")) && (
              <>
                <span>
                  {selectedImage
                    ? selectedImage?.name
                    : category.image && category.image !== ""
                    ? category.image?.split(/-(.*)/s)[1]
                    : ""}
                </span>
                <Tooltip title={"Preview image"}>
                  <Button
                    size="small"
                    onClick={() => setOpenPreviewDialog(true)}
                    style={{ ...buttonsStyle, marginRight: "15px" }}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <PreviewDialog
                  title={"Preview image"}
                  open={openPreviewDialog}
                  setOpen={setOpenPreviewDialog}
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : `${SERVER_URL}/${category.image}`
                  }
                />
                <Tooltip title="Delete">
                  <Button
                    size="small"
                    onClick={() => {
                      if (selectedImage) setSelectedImage(null);
                      else setCategory({ ...category, image: "" });
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </>
            )}
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={category.categoriamundotequila}
                  onChange={() =>
                    setCategory({
                      ...category,
                      categoriamundotequila: !category.categoriamundotequila,
                    })
                  }
                  name="checkCategoriamundotequila"
                />
              }
              label="Categoría mundo tequila"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={category.categoriamundococteleria}
                  onChange={() =>
                    setCategory({
                      ...category,
                      categoriamundococteleria: !category.categoriamundococteleria,
                    })
                  }
                  name="checkCategoriamundococteleria"
                />
              }
              label="Categoría mundo coctelería"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={category.active}
                  onChange={() =>
                    setCategory({ ...category, active: !category.active })
                  }
                  name="checkActive"
                />
              }
              label="Activa"
            />
          </CardBody>
        </Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => history.push("/categories")}
            variant="outlined"
            style={{ marginRight: "20px" }}
          >
            Atrás
          </Button>
          <Button
            onClick={() => saveCategory()}
            variant="outlined"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Guardar category
          </Button>
          {categoryId && (
            <>
              <MuiThemeProvider theme={theme}>
                <Button
                  onClick={() => setOpenConfirmDialog(true)}
                  variant="outlined"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                >
                  Eliminar category
                </Button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "auto",
                  }}
                ></div>
              </MuiThemeProvider>

              <ConfirmDialog
                title={"¿Estás seguro de que quieres eliminar esta category?"}
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                  deleteCategory(categoryId)
                    .then((res) => {
                      if (res.status === 204 || res.status === 200) {
                        alertSuccess({
                          title: "¡Eliminada!",
                          customMessage: "Category eliminada correctamente",
                        });
                        history.push("/categories");
                      }
                    })
                    .catch((error) => {
                      alertError({
                        error: error,
                        customMessage: "No se ha podido eliminar la category.",
                      });
                    });
                }}
              />
            </>
          )}
        </div>
      </>
    );
}
