import { /* baseClient, */ authClient, API } from "../index";
import { uploadSingleFile } from "../files";

// Get all downloads
export const getDownloads = () => {
  return authClient().get(`${API}/download`);
};

// Get download by id
export const getDownloadById = (id) => {
  return authClient().get(`${API}/download/${id}`);
};

// Delete download
export const deleteDownload = (id) => {
  return authClient().delete(`${API}/download/${id}`);
};

// Create download
export const postDownload = async (download, image) => {
  if (image) {
    download.file = {};
    for (const lang of Object.keys(image)) {
      if (image[lang]) {
        const response = await uploadSingleFile(image[lang], "downloads");
        download.file[lang] = response.data.fileURL;
      }
    }
  }
  return authClient().post(`${API}/download`, download);
};

//Update a Download
export const updateDownload = async (id, download, image) => {
  if (image) {
    download.file = download.file || {};
    for (const lang of Object.keys(image)) {
      if (image[lang]) {
        const response = await uploadSingleFile(image[lang], "downloads");
        download.file[lang] = response.data.fileURL;
      }
    }
  }
  return authClient().put(`${API}/download/${id}`, download);
};

// Count all types
export const countDownloads = () => {
  return authClient().get(`${API}/download/count`);
};

export const updateDownloadsOrder = (id, id2) => {
  return authClient().post(`${API}/download/swapDownloads`, {
    download1Id: id,
    download2Id: id2,
  });
};
