import { /* baseClient, */ authClient, API } from "../index";
import { uploadSingleFile } from "../files";

// Get all types
export const getTypes = () => {
  return authClient().get(`${API}/type`);
};

// Get type by id
export const getTypeById = (id) => {
  return authClient().get(`${API}/type/${id}`);
};

// Delete type
export const deleteType = (id) => {
  return authClient().delete(`${API}/type/${id}`);
};

// Create type
export const postType = async (type, mainImg) => {
  if (mainImg) {
    const response2 = await uploadSingleFile(mainImg, "types");
    type.mainImg = response2.data.fileURL;
  }
  return authClient().post(`${API}/type`, type);
};

// Update type
export const updateType = async (id, type, mainImg) => {
  if (mainImg) {
    const response2 = await uploadSingleFile(mainImg, "types");
    type.mainImg = response2.data.fileURL;
  }
  return authClient().put(`${API}/type/${id}`, type);
};

// Count all types
export const countTypes = () => {
  return authClient().get(`${API}/type/count`);
};

export const updateTypesOrder = (id, id2) => {
  return authClient().post(`${API}/type/swapTypes`, {
    type1Id: id,
    type2Id: id2,
  });
};
