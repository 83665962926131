import React, { useEffect, useState } from 'react';
import { alertError } from '../../utils/logger';
import { useHistory } from 'react-router-dom';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import CategoryIcon from '@material-ui/icons/Category'
import ClassIcon from '@material-ui/icons/Class'
import PublicIcon from '@material-ui/icons/Public'
import { countAdmins } from '../../api/user'
import { countProducts } from '../../api/product'
import { countCategories } from '../../api/category'
import { countTypes } from '../../api/type'
import { countBrands } from '../../api/brand'

export function DashboardPage() {
	const [data, setData] = useState([]);
    const history = useHistory()

	useEffect(() => {
		async function fetchData() {
			try {
				const admins = await countAdmins()
				const products = await countProducts()
				const categories = await countCategories()
				const types = await countTypes()
				const brands = await countBrands()

				setData({
					numAdmins: admins.data,
					numProducts: products.data,
					numCategories: categories.data,
					numTypes: types.data,
					numBrands: brands.data
				})
			} catch (err) {
				alertError({ error: err, customMessage: 'Could not get statistics.'})
			}
		}
		fetchData()
	}, []);

	return (
		<>
			<div className='row'>
				<div className='col-12'><h2>Catalogue</h2></div>
				<div className='col-12 col-md-3 text-center my-2' role='button' onClick={() => history.push('/products')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'><ArtTrackIcon/>  Products</h5>
							<div className='contentDash'>{data.numProducts}</div>
						</div>
					</div>
				</div>
				<div className='col-12 col-md-3 text-center my-2' role='button' onClick={() => history.push('/categories')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'><CategoryIcon/>  Categories</h5>
							<div className='contentDash'>{data.numCategories}</div>
						</div>
					</div>
				</div>
				<div className='col-12 col-md-3 text-center my-2' role='button' onClick={() => history.push('/types')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'><ClassIcon/>  Types</h5>
							<div className='contentDash'>{data.numTypes}</div>
						</div>
					</div>
				</div>
				<div className='col-12 col-md-3 text-center my-2' role='button' onClick={() => history.push('/brands')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'><PublicIcon/>  Brands</h5>
							<div className='contentDash'>{data.numBrands}</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row mt-4'>
				<div className='col-12'><h2>Users</h2></div>
				<div className='col-12 col-md-3 text-center my-2' role='button' onClick={() => history.push('/admins')}>
					<div className='card'>
						<div className='card-body'>
							<h5 className='card-title'><AssignmentIndIcon/>  Administrators</h5>
							<div className='contentDash'>{data.numAdmins}</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
